<template>
    <div class="block settingsBlock" :class="{ 'border-blink': !fileName }">
        <div class="row row--ai-center row--jc-space-between row--nowrap" >
            <p>Firebase Configuration</p>
            <p v-if="!fileName" class="warning blink attentionMsg row row--ai-center"> 
                <LdiIcon action="info"/>
                Pushes are not configured
            </p>
        </div>
        <div class="row row--ai-center row--jc-space-between row--nowrap">
            <div class="column" v-if="fileSource" style="max-width: 600px">
                <label :class="startEdit ? 'column' : 'row row--nowrap row--ai-center'">
                    Configuration file:
                    <p v-if="!startEdit" style="color: var(--input-color);">{{ fileName }}</p>
                    <div v-else>
                        <input ref="fileInput" :accept="['.json']" type="file" class="hidden-input" @change="onFileSelected">
                        <div class="row row--ai-center">
                            <LdiButton @click="openFileInput" :disabled="fileName ? true : false">Select file</LdiButton>
                            <span class="row row--ai-center row--nowrap" v-if="fileName">
                                {{ fileName }}
                                <LdiIcon action="close" @click="clearSelection()"/>
                            </span>
                        </div>
                    </div>
                </label>
            </div>
            <LdiButton v-if="startEdit" :disabled="isDisabled" action="save" icon 
            size="xs" class="saveBtn" @click="emitOnConfigured" />
            <LdiButton v-if="isCanEdit && !startEdit" action="edit" icon 
            size="xs" class="saveBtn" @click="startEdit = true" />
        </div>
    </div>
</template>

<script>
import LdiIcon from "@/Atoms/LdiIcon";
import LdiToggleButton from "@/Atoms/LdiToggleButton";
import LdiButton from "@/Atoms/LdiButton";
import FilePicker from '@/Organisms/File/LdiFilePicker.vue'
export default {
    name: "FireBaseConfigurator",
    emits: ["onConfiguredFile"],
    components: { LdiButton, LdiToggleButton, LdiIcon, FilePicker },
    props: {
        fileSource: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        appId: null,
        file: null,
        fileName: null,

        startEdit: false,
        isCanEdit: false,
    }),
    computed: {
        isDisabled() {
            return !this.appId
                || !this.fileName
        },

        getConfiguredObject() {
            return {
                appId: this.appId,
                configuration: this.file,
            };
        }
    },

    methods: {
        emitOnConfigured() {
            this.$emit("onConfiguredFile", this.getConfiguredObject);
            this.fileName = this.fileSource?.firebaseSettings?.file ?? null;
            this.isCanEdit = true;
            this.startEdit = false
        },
        onFileSelected({target: {files}}) {
            if (files?.length) {
                this.file = files[0]
                this.fileName = this.file?.name
            }
        },
        openFileInput() {
          this.$refs.fileInput.click();
        },
        clearSelection() {
            this.getConfiguredObject = { appId: this.appId }
        }
    },

    mounted() {
        if (this.fileSource) {
            this.appId = this.fileSource?.id;
            this.file = this.fileSource?.configuration;
            this.fileName = this.fileSource?.firebaseSettings?.file ?? null;
        }

        this.isCanEdit = Boolean(Object.keys(this.fileSource?.firebaseSettings)?.length)

        if (!this.isCanEdit) {
            this.startEdit = true
        }
    }
};
</script>
<style>
.hidden-input {
  display: none;
}
.saveBtn{
    margin-bottom: auto;
    align-items: flex-end !important;
}
</style>