import {createRouter, createWebHistory} from "vue-router";
import {routes} from './Packages/routes/index'
import Utils from './Packages/utils'
import {auth as Storage} from "./Packages/storage";
import {getTitleLinkName} from './Packages/config/menu'

export const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach((to, from, next) => {
    if (to.name === 'login' || to.name === 'oauth.google') {
        next()
        updateTitle('Login')
        return
    }

    if (Utils.auth.isNewUser()) {
        next({name: 'login'})
        updateTitle('Login')
        return
    } else if (Utils.auth.isTokenExpires()) {
        Storage.setRouteLinkRedirectAfterReLogIn(to.fullPath)
        next({name: 'login'})
        updateTitle('Login')
        return
    } else if (to?.meta?.permission && !Utils.auth.isUserCan(to.meta.permission)) {
        next({name: 'login'})
        updateTitle('Login')
        return
    }

    next()
    updateTitle(getTitleLinkName(to.name) ?? 'Octopus')
})

const updateTitle = title => {
    document.title = title
}