<template>
    <div :class="['homepage', this.colorTheme]" @click="closeModal">
        <SidebarComponent/>
        
        <notifications />

        <div id="body-scroll" class="content">
            <HeaderComponent/>
            <router-view/>
            <!--                        <FooterComponent />-->
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

import SidebarComponent from "./Menu/SidebarComponent.vue";
import HeaderComponent from "./Menu/HeaderComponent.vue";
import FooterComponent from "./Menu/FooterComponent.vue";

export default {
    name: "HomeComponent",
    components: {
        FooterComponent,
        HeaderComponent,
        SidebarComponent,
    },
    methods: {
        closeModal(e) { // @todo - отрефакторить
            if (window.screen.width <= 768) {
                if (e.target.classList.contains('dashboard') || e.target.classList.contains('header')) {
                    document.querySelector('.sidebar-list').classList.add('none');
                    document.querySelector('.sidebar').classList.remove('sidebar--open');
                    document.querySelector('.sidebar').classList.remove('sidebar--open-light');
                }
            }
        }
    },

    computed: {
        ...mapGetters(['colorTheme']),
    }
};
</script>