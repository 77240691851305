<template>
    <AppsLdiPage>

        <LdiButton action='add' icon @click='create' />

        <AppsPageFilter @onFilter="filter"/>

        <AppsTable
            :paginated='getApps' @onEdit='edit' @onOrder='order' @onPaginate='paginate' 
            @onRemove="showRemoveConfirm($event, removeApp, 'remove')" 
            @onRestore="showRemoveConfirm($event, restoreApp, 'restore')" @onShow='show'/>

        <LdiPrompt
            :isDisabled="isConfirmDisabled"
            :is-show='isShowDeleteConfirm'
            :messages=confirmMessage
            @onCancel='closeRemoveConfirm' @onConfirm='confirm' />

    </AppsLdiPage>
</template>

<script>
import AppsLdiPage from '@/Pages/AppsLdiPage'

import { createNamespacedHelpers } from 'vuex'
import AppsTable from '@/Components/Apps/Table/AppsTable'
import LdiButton from '@/Atoms/LdiButton'
import LdiPrompt from '@/Organisms/Popup/LdiPrompt'
import AppsPageFilter from '@/Components/Apps/Filters/AppsPageFilter'
const { mapGetters, mapActions } = createNamespacedHelpers('apps')

export default {
    components: { LdiPrompt, LdiButton, AppsTable, AppsLdiPage, AppsPageFilter },
    data: () => ({
        filters: null,
        page: null,
        orderConfiguration: {
            orderBy: 'keyId',
            orderDirection: 'desc',
        },

        isShowDeleteConfirm: false,
        idToRemove: null,

        confirmMessage: null,
        action: null,
        isConfirmDisabled: false,
    }),
    methods: {
        ...mapActions(['loadApps', 'removeApp', 'restoreApp']),

        order(orderConfiguration) {
            this.orderConfiguration = orderConfiguration
            this.loadApps({ ...orderConfiguration, page: this.page })
        },
        filter(filterData){
            Object.assign(this.orderConfiguration, filterData);
            this.loadApps({ ...this.orderConfiguration, page: this.page })
        },
        paginate(page) {
            this.page = page
            this.loadApps({ ...this.orderConfiguration, page })
        },

        create() {
            this.$router.push({ name: 'apps.create' })
        },
        show(id) {
            this.$router.push({ name: 'apps.edit', params: { id }, query: { startTabName: 'Event' } })
        },
        edit(id) {
            this.$router.push({ name: 'apps.edit', params: { id }, query: { startTabName: 'General' } })
        },
        confirm() {
            this.isConfirmDisabled = true

            this.action(this.idToRemove).finally(() => {
                this.loadApps({ ...this.orderConfiguration })
                this.closeRemoveConfirm()
                this.isConfirmDisabled = false
            })
        },

        showRemoveConfirm(id, action, actionName) {
            this.idToRemove = id
            this.isShowDeleteConfirm = true
            this.action = action
            this.confirmMessage = actionName === 'remove' 
                ? ["Are you sure?", "All data will be ARCHIVED!"]
                : ["Are you sure?", "All data will be RESTORED!"]
        },

        closeRemoveConfirm() {
            this.idToRemove = null
            this.isShowDeleteConfirm = false
        }
    },
    computed: {
        ...mapGetters(['getApps'])
    },
    mounted() {
        this.loadApps({ ...this.orderConfiguration })
    }
}
</script>