<template>
    <div :class="['sidebar', {'open': is_open} ]">
        <nav ref="main_menu" :class="['sidebar-block', { 'sidebar-block--open': is_open }]">
            <span :class="['sidebar-title', {'is-select' : isMob }]" @click="sidebarToggle">
                Menu
                <Icon :icon="is_open? 'bxs:up-arrow':'bxs:down-arrow'" class="sidebar-arrow" />
            </span>
            <ul class="sidebar-list">
                <SidebarItemComponent v-for="menu in listMenu" :key="menu.text" :children="menu.children ?? []"
                                      :icon="menu.icon"
                                      :link="menu.link ?? {}"
                                      :perm="menu.perm ?? ''"
                                      :text="menu.text"
                                      class="sidebar-item" @close:menu="closeMenu" />
            </ul>
        </nav>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Icon } from "@iconify/vue";
import { auth } from "@/Packages/utils";

import SidebarItemComponent from "./SidebarItemComponent";
import { getConfigMenu } from '@/Packages/config/menu'
import { getRoutesNamesAvailableForUser } from "@/Packages/routes";

export default {
    components: {
        Icon,
        SidebarItemComponent
    },

    data: vm => ({
        is_open: false
    }),

    watch: {
        isMob(state) {
            if (!state) {
                this.closeMenu();
            }
        },
        is_open(state) {
            if (state) {
                document.addEventListener("click", this.handlerCloseMainMenu, true);
            } else {
                document.removeEventListener("click", this.handlerCloseMainMenu, true);
            }
        }
    },

    methods: {
        can: auth.isUserCan,

        sidebarToggle(e) {
            if (this.isMob) {
                this.is_open = !this.is_open;
            }
        },

        closeMenu() {
            this.is_open = false;
        },

        handlerCloseMainMenu(e) {
            if (!e.composedPath().includes(this.$refs.main_menu)) {
                this.closeMenu();
            }
        },

        filterMenu(elems) {
            const menuEx = getRoutesNamesAvailableForUser();
            if (menuEx && menuEx.length > 0) {
                if (elems) {
                    return elems
                        .map(el => {
                            if (el.children) {
                                el.children = this.filterMenu(el.children);
                            }
                            return el;
                        })
                        .filter(el => {
                            if (el.link && el.link.name) {
                                return menuEx.includes(el.link.name);
                            } else if (!el.link && el.children && el.children.length === 0) {
                                return false;
                            }

                            return true;
                        });
                }
            }
            return [];
        }
    },
    computed: {
        ...mapGetters(["isMob"]),

        listMenu() {
            return this.filterMenu(getConfigMenu())
        }
    }
};
</script>