<template>
    <div class='scroll-thin' style='overflow-x: auto'>
        <LdiPaginatedTable
            :initialOrderBy='initialOrderBy'
            :paginated='paginated'
            :rowHeaderCellsConfiguration='getRowHeaderCellsConfiguration()'
            @onOrder='emitOnOrder' @onPaginate='emitOnPaginate'
        >
            <tr v-for='app in paginated.data'>
                <td>{{ app.id }}</td>
                <td style='white-space: nowrap;overflow: hidden;text-overflow: ellipsis; max-width: 250px'>
                    {{ app.name }}
                </td>
                <td>{{ app.url ?? '-' }}</td>
                <td>
                    <CopyableText :text="app.token ?? '-'" no-wrap />
                </td>
                <td>{{ app.region ?? '-' }}</td>
                <td>{{ date(app.created_at) ?? '-' }}</td>
                <td>{{ date(app.updated_at) ?? '-' }}</td>
                <td>
                    <div class='row row-ai-center row--nowrap'>
                        <LdiButton action='edit' icon size='s' @click='emitOnEdit(app.id)' />
                        <LdiButton action='remove' icon size='s' @click='emitOnRemove(app.id)' />
                    </div>
                </td>
            </tr>
        </LdiPaginatedTable>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import LdiPaginatedTable from '@/Organisms/Table/LdiPaginatedTable'
import LdiButton from '@/Atoms/LdiButton'
import CopyableText from '@/Molecules/Text/CopyableText'
import { date } from '@/Packages/utils'

const { mapGetters, mapActions } = createNamespacedHelpers('apps')

export default {
    components: { CopyableText, LdiButton, LdiPaginatedTable },
    emits: ['onPaginate', 'onOrder', 'onEdit', 'onRemove'],
    props: {
        paginated: {
            type: Object
        },
        initialOrderBy: String
    },
    methods: {
        getRowHeaderCellsConfiguration() {
            return [
                {
                    cellName: '#',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyId'
                },
                {
                    cellName: 'Name',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyName'
                },
                {
                    cellName: 'Url',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyUrl'
                },
                {
                    cellName: 'Token',
                    isOrderAble: false
                },
                {
                    cellName: 'Region',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyR'
                },
                {
                    cellName: 'Created',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyC'
                },
                {
                    cellName: 'Updated',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyU'
                },
                {
                    cellName: 'Actions',
                    isOrderAble: false
                }
            ]
        },
        emitOnOrder(orderConfiguration) {
            this.$emit('onOrder', orderConfiguration)
        },
        emitOnPaginate(page) {
            this.$emit('onPaginate', page)
        },
        emitOnEdit(id) {
            this.$emit('onEdit', id)
        },
        emitOnRemove(id) {
            this.$emit('onRemove', id)
        },
        date(value) {
            return date.datetimeDefault(value)
        }
    }
}
</script>