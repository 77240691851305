<template>
    <div class="row row--ai-center">
        <input type="text" v-model="search" placeholder="search" class="middle">
        <select v-model="status">
            <option v-for="(status, index) in $options.STATUSES" :key="index" :value="status">
                {{ status }}
            </option>
        </select>
        <LdiButton @click="emitOnFilter" :action="'filter'" color="primary" icon size='s'/>
    </div>
</template>

<script>
import LdiButton from '@/Atoms/LdiButton'

export default {
    name: "AppsPageFilter",
    emits: ['onFilter'],
    STATUSES: ['active', 'deleted', 'all'],
    components: { LdiButton},

    data() {
        return {
            search: '',
            status: 'active',
        };
    },
    methods:{
        emitOnFilter() {
            const filterData = {
                search: this.search,
                status: this.status,
            };
            this.$emit('onFilter', filterData);
        },
    }
}
</script>