<template>
    <create-user-configurator pageName="buyer" :createUser="createBuyer" :loadUsers="loadBuyers"
        :getUsers="getBuyers"/>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapActions: mapActionsBuyers, mapGetters: mapGettersBuyers } = createNamespacedHelpers('buyers')

import CreateUserConfigurator from './Components/CreateUserConfigurator.vue'

export default {
    components: { CreateUserConfigurator },

    mounted() {
        this.loadBuyersListRoles()
    },
    computed: {
        ...mapGettersBuyers(['getBuyers'])
    },
    methods: {
        ...mapActions([ 'loadBuyersListRoles' ]),
        ...mapActionsBuyers(['createBuyer', 'loadBuyers']),  
    }
}
</script>
<style scoped>
.v-select {
    width: 100%;
    margin: 0;
}
</style>