<template>
    <div class="block-row block-row--gray file-picker">
        <div class="file-picker__content">
            <input :accept="acceptedFileTypes" type="file" @change="onChange">
        </div>
    </div>
</template>

<script>

export default {
    props: {
        acceptedFileTypes: Array
    },
    emit: {
        onPick: null,
    },
    methods: {
        onChange({target: {files}}) {
            this.$emit('onPick', files)
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base/mixins';

.file-picker {
    width: fit-content;
    @include flexRow();
    gap: .5rem;

    &__content {
        display: flex;
        align-items: center;
        gap: .5rem;

        input{
            min-height: 48px;
        }
    }
}
</style>