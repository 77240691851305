<!--
   массив с настройками ячейки
   cells:[
    {
        cellName: "текст ячейки",
        isOrderAble: true|false - будем по ней сортировать или нет,
        cellNameOrderAble: имя колонки по которой сортируем - параметр по которому будет отсортирован запрос на сервере
    },
   ]
-->

<template>
    <thead>
        <tr>
            <LdiCellHeaderTable v-for="cell in cells" v-bind="cell" v-bind:key="cell"
                :initIsAscOrderDirection="isAscOrderDirection" :is-show-order-icon="orderBy === cell.cellNameOrderAble"
                @onOrder="handleCellOnOrder" :tooltip="cell?.tooltip" :lastLabel="cells[cells.length - 2].cellName" />
        </tr>
    </thead>
</template>

<script>

import LdiCellHeaderTable from "../../Molecules/Cell/LdiCellHeaderTable";

export default {
    name: "LdiRowHeaderTable",
    emits: ['onOrderByCell'],
    components: { LdiCellHeaderTable },
    props: {
        cells: {
            type: Array,
            required: true,
            validator: val => {
                return !val.some(item => {
                    return !item.hasOwnProperty('cellName')
                        || (item.hasOwnProperty('isOrderAble') && item.isOrderAble)
                        ? !item.hasOwnProperty('cellNameOrderAble')
                        : false

                })
            }
        },
        initialOrderBy: String,
    },
    data: () => ({
        orderBy: null,
        isAscOrderDirection: false,
    }),
    mounted() {
        if (this.initialOrderBy) {
            this.orderBy = this.initialOrderBy
        }
    },
    methods: {
        handleCellOnOrder(orderConfiguration) {
            this.isAscOrderDirection = this.orderBy !== orderConfiguration.orderBy ? false : !this.isAscOrderDirection
            this.orderBy = orderConfiguration.orderBy
            this.$emit('onOrderByCell', { ...this.getOrderConfiguration, ...orderConfiguration })
        },
    },
    computed: {
        getOrderConfiguration() {
            return {
                orderDirection: this.isAscOrderDirection ? 'asc' : 'desc',
            }
        }
    }
}
</script>