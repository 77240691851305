<template>
    <PostbacksPageFilter @onFilter="filter" @onClearFilter="filter" :filters="getFilters" />
    <div class='row row--nowrap' :style='{ gap: "0", minHeight: screenHeight + "px", }'>
        <div ref='postbackTableBox' :class='getClassesTableBox'>
            <AppStatisticsTable ref='postbackTable' :paginated='getPostbacks' :postbackIp="getFilters?.ip" @onCopy='copy'
                :class="{ 'box-scrollDisabled': isScrollDisabled }" @onOrder='order' @onPaginate='paginate' @onShow='show' />
        </div>

        <JsonViewer ref='jsonbox' v-if='jsonToShow && jsonFrom' :class='getClassesJsonBox' :json='jsonToShow'
            :jsonFrom='jsonFrom' @onHide='resetShow' 
            :isMore="isMore" />
    </div>
</template>

<script>
import { computed } from 'vue'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('postbacks')
import AppsLdiPage from '@/Pages/AppsLdiPage'
import LdiSpinner from "@/Atoms/LdiSpinner.vue";
import AppStatisticsTable from '@/Components/Apps/Table/AppStatisticsTable'
import JsonViewer from '@/Components/Apps/Component/JsonViewer'
import PostbacksPageFilter from '@/Components/Apps/Filters/PostbacksPageFilter.vue'

export default {
    components: { JsonViewer, AppStatisticsTable, AppsLdiPage, PostbacksPageFilter, LdiSpinner },

    props: {
        appId: {
            type: Number,
            default: null
        }
    },

    data: () => ({
        breadcrumbs: { text: 'Show app: ' },

        jsonFrom: null,
        jsonToShow: null,

        page: null,
        orderConfiguration: {
            orderBy: 'keyDate',
            orderDirection: 'desc'
        },
        boxHeight: null,
        tableHeight: null,
        isMore: false,
        jsonboxPosition: null,
        screenHeight: null,
        isScrollDisabled: false
    }),
    methods: {
        ...mapActions(['loadPostbacks', 'loadFilters']),

        order(orderConfiguration) {
            this.resetShow()
            this.orderConfiguration = orderConfiguration
            this.loadPostbacks({ appId: this.$route.params.id, ...orderConfiguration, page: this.page })
        },

        paginate(page) {
            this.resetShow()
            this.page = page
            this.loadPostbacks({ appId: this.$route.params.id, ...this.orderConfiguration, page })
        },

        copy(json) {
            navigator.clipboard.writeText(JSON.stringify(json))
        },

        show(json, jsonFrom) {
            if (this.jsonToShow === null) {
                window.scrollTo(window.pageXOffset, (window.pageYOffset + 1))
            }

            this.jsonFrom = jsonFrom
            this.jsonToShow = JSON.stringify(json, null, 8)
        },
        resetShow() {
            this.jsonToShow = null
            this.$refs.postbackTable.resetClickedItem()
        },
        filter(filterData){
            Object.assign(this.orderConfiguration, filterData);
            this.loadPostbacks({ appId: this.$route.params.id, ...this.orderConfiguration, page: null })
        },
    },
    computed: {
        ...mapGetters(['getPostbacks', 'getFilters']),

        getTableRightPosition() {
            this.jsonboxPosition = this.$refs.jsonbox.$el.getBoundingClientRect().left
            return this.$refs.jsonbox.$el.getBoundingClientRect().left            
        },

        getClassesTableBox() {
            return {
                'box-table': !this.jsonToShow,
                'box-table--view-json': this.jsonToShow
            }
        },

        getClassesJsonBox() {
            return {
                'block': true,
                'block--full': true,
                'bg-soft': true,
                'box-json': !this.jsonToShow,
                'box-json--fixed': this.jsonToShow
            }
        },
    },
    watch: {
        jsonToShow(newSource) {
            if (newSource) {
                this.$nextTick(() => {
                    const postbackTableBox = this.$refs.postbackTable.$el;
                    const tableHeight = postbackTableBox.getBoundingClientRect().height;
                    this.tableHeight = tableHeight
                    const jsonboxElement = this.$refs.jsonbox.$el;
                    const boxHeight = jsonboxElement.getBoundingClientRect().height;
                    this.boxHeight = boxHeight;
                    const screenHeight = window.innerHeight;
                    this.screenHeight = screenHeight

                    window.addEventListener('resize', () => {
                        const screenHeight = window.innerHeight;
                        this.screenHeight = screenHeight
                    });
                    if (this.tableHeight< this.screenHeight || this.screenHeight < this.boxHeight) {
                        this.isMore = true
                    }
                });
            }

        },
        jsonboxPosition(newSource) {
            // console.log('jsonboxPosition', newSource);
            if (newSource) {
                this.isScrollDisabled = true

            }
        }
    },
    mounted() {
        if (this.appId) {
            this.loadPostbacks({ appId: this.appId, ...this.orderConfiguration })
            this.loadFilters(this.appId)
        }
        
    },

    provide() {
        return {
            tableRightPosition: computed(() => this.getTableRightPosition)
        }
    }
}
</script>

<style lang='scss' scoped>
.box {
    &-scrollDisabled{
        overflow-x: hidden !important;
    }
    &-table {
        flex: 1 0 auto;

        &--view-json {
            flex: 0 0 auto;
        }
    }

    &-json {
        flex: 0 0 30%;
        border-radius: 0;
        margin-right: 1rem;
    }
}
</style>