/** Эти мидлвары обязаны возвращать новый объект конфигурации запроса */

export const addBearerToken = (config, store, router) => {
    return {
        ...config,
        headers: {
            ...config.headers,
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${store.getters.getAccessToken}`,
        }
    }
}

export const startLoading = (config, store) => {
    store.commit('setIsLoading', true)
    return config
}