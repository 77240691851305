<template>
    <AuthPopup v-if="showAuthPopup" @login="handleLogin"/>
    <router-view />
</template>

<script>
import AuthPopup from "@/Components/Auth/AuthPopup";
import { auth as Storage } from '@/Packages/storage'

export default {
    components: { AuthPopup },
    data() {
        return {
            showAuthPopup: false,
        };
    },
    methods: {
        checkAuthentication() {
            const isAuthenticated = Storage.getToken().expiresAt > Date.now()
            if (!isAuthenticated&&!this.isLoginPage()) {
                this.showAuthPopup = true;
            }
        },
        handleLogin() {
            this.showAuthPopup = false;
            this.$router.push({ name: 'login' })
            Storage.setRouteLinkRedirectAfterReLogIn(this.$route.fullPath)
        },
        startTimer() {
            setInterval(() => {
                this.checkAuthentication()
            }, 300000);
        },
        isLoginPage() {
            return window.location.pathname.includes("/login");
        }
    },
    mounted() {
        this.startTimer();
    },
};
</script>