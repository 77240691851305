import Api from '../../Packages/api'
import {auth as Storage} from '../../Packages/storage'

export default {
    state: {
        user: Storage.getUser() || {},
        token: Storage.getToken() || {},
        authError: null,
    },

    getters: {
        user: state => state.user,
        getUserId: state => Number.parseInt(state.user.id),
        showUserName: state => state.user.name ?? '',
        getAccessToken: state => state.token.accessToken,
        isTokenExpired: state => state.token.expiresAt < Date.now(),
        getAuthError: state => state.authError,
    },

    mutations: {
        loggedIn(state, {user, token}) {
            state.user = user
            state.token = token
            Storage.setUser(user)
            Storage.setToken(token)
        },
        loggedOut(state) {
            state.user = {}
            state.token = {}
            Storage.removeUser()
            Storage.removeToken()
        },
        setAuthError(state, error) {
            console.log(error);
            state.authError = error
        },
    },

    actions: {
        loginViaGoogle({commit}, code) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.google.logIn(code)
                    .then(data => {
                        commit('loggedIn', data)
                        commit("setAuthError", null)
                        resolve(data)
                    })
                    .catch(reject)
            })
        },

        login({commit}, user) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.sanctum.logIn(user)
                    .then(data => {
                        commit("setAuthError", null)
                        commit('loggedIn', data)
                        resolve(data)
                    })
                    .catch(reject)
            })
        },

        logout({commit}) {
            // если в сторе нет объекта user и token, то не нужно разлогиниваться чтоб не зациклить вызов logout
            if (Object.keys(Storage.getUser()).length && Object.keys(Storage.getToken()).length) {
                return new Promise((resolve, reject) => {
                    Api.octopus.analytic.v1.sanctum.logOut()
                        .then(() => {
                            commit('loggedOut')
                            resolve()
                        })
                        .catch(reject)
                })
            }
        }
    },
}