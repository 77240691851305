import  {getRandomInt} from './number'

export const ucFirst = str => str.charAt(0).toUpperCase() + str.slice(1)

export const camelCaseToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const generateToken = (length = 32) => {
    const source ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let token = ''

    while (token.length < 32){
        token += source[getRandomInt(0, source.length-1)]
    }

    return token
}