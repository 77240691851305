<template>
	<date-picker :placeholder="placeholder" :value-type="format" lang="uk" 
	:format="format" :type="type??period" clearable autocomplete/>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import 'vue-datepicker-next/locale/uk';

export default {
	name: "DatePickerComponent",
	components: {
		DatePicker
	},
	data: () => ({
		format: '',
	}),
	mounted() {
		this.definedFormat()
	},
	props: {
		period: String,
		type: {
			type: String,
			default: 'date'
		},
		multiple: Boolean
	},
	watch: {
		period(state) {
			this.definedFormat()
		}
	},
	computed: {
		placeholder() {
			return `Select ${this.period}`
		},
	},
	methods: {
		definedFormat() {
			const allFormats = [{'day': 'YYYY-MM-DD'}, {'week': 'YYYY-ww'}, {'month': 'YYYY-MM'}]
			allFormats.forEach(format => {
				for (const p in format) {
					const valueType = format[p];
					if (p === this.period) {
						this.format = valueType;
					}
				}
			})

		},
	}
}
</script>