<template>
    <div :class="['notification', 'notification--' + this.type]">
        <LdiIcon :action="getIconAction" color="white" size="m"/>
        <div class="notification__body" @click="close">
            {{ message }}
        </div>
        <LdiIcon v-if="showClose" action="close" color="black" size="l" @click="emitOnClose"/>
    </div>
</template>

<script>

export default {
    name: "Notification",
    emits: ['onClose'],
    props: {
        action: {
            type: String,
            default: 'alert',
            validator: value => ['alert', 'notification', 'link'].includes(value)
        },
        message: {
            type: String,
            required: true,
        },
        timestamp: {
            type: Date,
            default: () => {
                const date = new Date()
                date.setMilliseconds(data.getMilliseconds() + Math.floor(Math.random() * (Math.random()+40) + 1));
                return date
            }
        },
        timeout: {
            type: Number,
            default: 20000,
            validator: value => value > 0
        },
        type: {
            type: String,
            default: 'info',
            validator: value => ['info', 'primary', 'danger', 'warning', 'success'].includes(value)
        },
        closeOnClick: {
            type: Boolean,
            default: true
        },
        showClose: {
            type: Boolean,
            default: true
        },
        disableAutoClose: {
            type: Boolean,
            default: false,
        }
    },

    mounted() {
        if(!this.disableAutoClose){
            setTimeout(this.emitOnClose, this.timeout)
        }
    },

    methods: {
        emitOnClose() {
            this.$emit('onClose', this.timestamp)
        },
        close() {
            if(this.closeOnClick){
                this.emitOnClose()
            }
        }
    },
    computed: {
        getIconAction() {
            switch (this.action) {
                case 'link':
                    return 'link'

                case 'notification':
                    return 'bell'

                case 'alert':
                default:
                    return 'info'
            }
        }
    }
}
</script>