import { deleteRequest, getRequest, postRequest, putRequest } from '../octopusAnalyticRequests'

const uri = 'apps'

export const create = ({ name, bundle, domain, vertical, keys, privateKeys, userIds }) =>
    postRequest(uri, { name, bundle, domain, vertical, keys, privateKeys, userIds })

export const read = id => getRequest(uri + `/${id}`)

export const update = ({ id, name, bundle, domain, vertical, keys, privateKeys, userIds }) =>
    putRequest(uri + `/${id}`, { name, bundle, domain, vertical, keys, privateKeys, userIds })

export const remove = id => deleteRequest(uri + `/${id}`)

export const restore = id => putRequest(uri + `/${id}/restore`)

export const all = ({ page, orderBy, orderDirection, status, search }) =>
    getRequest(uri, { page, orderBy, orderDirection, status, search })

export const postbacks = ({ appId, clientId, page, orderBy, orderDirection, search, type, startDate, endDate, emptyPostbacksDomain, streamUid }) =>
    getRequest(`${uri}/${appId}/postbacks`, { clientId, page, orderBy, orderDirection, search, type, startDate, endDate, emptyPostbacksDomain, streamUid })

export const filters = appId => getRequest(uri + `/${appId}/postbacks/filters`)
