<template>
    <AppsLdiPage :breadcrumbs='breadcrumbs'>
        <LdiTabs :tab-names="$options.TABS" 
            :initialActiveTabName="this.$route.query.startTabName">
            <template v-slot="fromSlot">
                <LdiTab v-bind="fromSlot" :name="$options.TABS[0]">
                    <AppConfigurator v-if='app' :source='app' 
                        @onConfigured="showConfirmPopup($event, updateApp, 'updateApp')"
                        @onConfiguredFile="showConfirmPopup($event, updatePushFirebase, 'updatePushFirebase')"/>
                </LdiTab>
                <LdiTab v-bind="fromSlot" :name="$options.TABS[1]">
                    <ShowAppComponent v-if='app' :appId='app?.id'/>
                </LdiTab>
            </template>
        </LdiTabs>
        <LdiPrompt
            :is-show='isShowConfirmPopup' :isDisabled="isConfirmDisabled"
            :messages='["Are you sure?", "Please check all data TWICE!"]'
            @onCancel='closeConfirmPopup' @onConfirm='update'/>
    </AppsLdiPage>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('apps')
import LdiPrompt from '@/Organisms/Popup/LdiPrompt'
import LdiTabs from '@/Atoms/Tab/LdiTabs.vue'
import LdiTab from '@/Atoms/Tab/LdiTab.vue'
import AppsLdiPage from '@/Pages/AppsLdiPage'
import AppConfigurator from '@/Components/Apps/Component/AppConfigurator'
import ShowAppComponent from '@/Components/Apps/Component/ShowAppComponent.vue'

export default {
    TABS: ['General', 'Event'],
    name: "EditAppPage",
    components: {
        LdiPrompt, AppConfigurator, AppsLdiPage, LdiTabs, LdiTab, ShowAppComponent
    },
    data: () => ({
        breadcrumbs: {},
        app: null,

        isShowConfirmPopup: false,
        configuredObject: null,
        action: null,
        component: null,
        isConfirmDisabled: false,
    }),
    watch: {
        configuredObject(newObj, oldObj) {
            if (newObj !== oldObj) {
                this.isConfirmDisabled = false
            }
        }
    },
    methods: {
        ...mapActions(['loadApp', 'updateApp', 'updatePushFirebase']),

        showConfirmPopup(configuredObject, action, component) {
            this.isShowConfirmPopup = true
            this.configuredObject = configuredObject
            this.action = action
            this.component = component
        },

        closeConfirmPopup() {
            this.isShowConfirmPopup = false
            this.configuredObject = null
        },

        update() {
            this.isConfirmDisabled = true
            this.action({ id: this.$route.params.id, ...this.configuredObject })
            .finally(() => {
                this.closeConfirmPopup()
                setTimeout(() => {
                    this.loadApp(this.$route.params.id)
                        .then(app => {
                            this.app = app
                            this.breadcrumbs.text = 'Edit app: ' + app.name
                        })
                }, 1000);
                this.isConfirmDisabled = false
            })
        },
    },

    mounted() {
        this.loadApp(this.$route.params.id).then(app => {
                this.app = app
                this.breadcrumbs.text = 'Edit app: ' + app.name
            }
        )
    },
}
</script>