<template>

    <LdiIcon v-if='verticalValue===$options.TYPES.BET' action='casino' color='primary' size='m' />
    <LdiIcon v-else-if='verticalValue===$options.TYPES.CASINO' action='slot' color='success' size='m' />
    <LdiIcon v-else-if='verticalValue===$options.TYPES.SOCIAL_CASINO' action='poker' color='warning' size='m' />
    <LdiIcon v-else-if='verticalValue===$options.TYPES.FANTASY' action='fantasy' color='default' size='m' />
    <LdiIcon v-else-if='verticalValue===$options.TYPES.FINANCE' action='dollar' color='secondary' size='m' />
    <LdiIcon v-else action='question' color='danger' />

</template>

<script>
import LdiIcon from '@/Atoms/LdiIcon'
import { verticals } from '@/Packages/config'

export default {
    TYPES: verticals,
    components: { LdiIcon },
    props: {
        verticalValue: String
    }
}
</script>

<style scoped>

</style>