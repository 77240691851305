export const URL_OCTOPUS_ANALYTIC_V1 = process.env.VUE_APP_API_URL.replace(/\/$/, "").trim() + '/api/'

export const IS_NEED_PRINT_LOGS = process.env.VUE_APP_IS_NEED_PRINT_LOGS === 'true'

export default {
    useStore: function (store) {
        this._store = store
        return this
    },
    useRouter: function (router) {
        this._router = router
        return this
    },
    useMiddlewares: function (middlewares) {
        this._middlewares = middlewares
        this.setRequestMiddlewares()
        this.setResponseMiddlewares()
        this.setErrorMiddlewares()
        return this
    },

    setRequestMiddlewares: function (middleware) {
        this._requestMiddlewares = middleware ?? this._middlewares.request
        return this
    },
    useRequestMiddlewares: function (requestConfig) {
        if (!isExistsMiddleware(this._requestMiddlewares)) return requestConfig

        return this._requestMiddlewares.reduce((accumulator, middleware) => {
            return { ...accumulator, ...middleware(accumulator, this._store, this._router) }
        }, requestConfig)
    },

    setResponseMiddlewares: function (middleware) {
        this._responseMiddlewares = middleware ?? this._middlewares.response
        return this
    },
    useResponseMiddlewares: function (response) {
        if (!isExistsMiddleware(this._responseMiddlewares)) return response

        return this._responseMiddlewares.reduce((accumulator, middleware) => {
            return { ...accumulator, ...middleware(accumulator, this._store, this._router) }
        }, response)
    },

    setErrorMiddlewares: function (middleware) {
        this._errorMiddlewares = middleware ?? this._middlewares.error
        return this
    },
    useErrorMiddlewares: function (error) {
        this._errorMiddlewares?.forEach((middleware) => {
            middleware(error, this._store, this._router)
        })
    }
}

const isExistsMiddleware = (middleware) => {
    // console.log("isExistsMiddleware:", middleware)
    return middleware && Array.isArray(middleware) && middleware.length
}
