<template>
    <RolesLdiPage :breadcrumbs="breadcrumbs">
        <label class="column">
            Title
            <input v-model="name" placeholder="Manager" type="text">
        </label>
        <div class="column">
            <span>Permissions</span>
            <div v-for="permission in getListPermissions" :key="permission.id">
                <label class="label-flex">
                    <input :checked="roleHasPermission(permission.id)" :value="permission.id"
                           type="checkbox" @change="handleChoice($event)">
                    <strong>{{ permission.label }}</strong> {{ !!permission.description ? ` - ${permission.description}` : '' }}
                </label>
            </div>
        </div>
        <LdiButton action="submit" @click.prevent="update" :disabled="isDisabled">Update</LdiButton>
    </RolesLdiPage>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LdiButton from "@/Atoms/LdiButton.vue";
import RolesLdiPage from "@/Pages/RolesLdiPage.vue";

export default {
    components: {LdiButton, RolesLdiPage},
    data() {
        return {
            name: '',
            checkedPermissions: [],
            startCheckedPermissions: [],
        }
    },
    props: {
        loadUsersListRoles: Function,
        loadUsersListPermissions: Function,
        pageName: String
    },
    mounted() {
        this.loadUsersListRoles()
        this.loadUsersListPermissions()
        this.loadRole(this.$route.params.id)
            .then(data => {
                this.name = data.name
                this.checkedPermissions = data.permissions.map(el => el.id)
                this.startCheckedPermissions = data.permissions.map(el => el.id)
            })
            .catch(() => this.$router.go(-1))
    },
    methods: {
        ...mapActions(['loadRole', 'updateRole',]),
        update() {
            this.updateRole({
                id: this.$route.params.id,
                name: this.name,
                permissionIds: this.checkedPermissions
            })
            this.$router.go(-1)
        },

        roleHasPermission(id) {
            return this.checkedPermissions.includes(id)
        },

        handleChoice(e) {
            const permissionID = parseInt(e.target.value)

            if (e.target.checked) {
                this.checkedPermissions.push(permissionID)
            } else {
                const index = this.checkedPermissions.indexOf(permissionID);
                if (index !== -1) {
                    this.checkedPermissions.splice(index, 1);
                }
            }
        }
    },

    computed: {
        ...mapGetters(['getListPermissions']),
        isDisabled() {
            return JSON.stringify(this.checkedPermissions) === JSON.stringify(this.startCheckedPermissions);
        },
        breadcrumbs() {
            return {text: `Edit ${this.pageName}'s role: ` + this.name}
        },
    },
}
</script>