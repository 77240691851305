<template>
    <div class='login dark'>
        <div class='login-container'>
            <div v-if="authMethod === 'email' || authMethod === 'both'" class='login-block'>
                <form action='' class='block' @submit.prevent='authorizationViaEmailPassword'>
                    <input name='remember' type='hidden' value='true'>
                    <label for='email'>
                        <span :class="{'danger': isError}">Email</span>
                        <input id='email' v-model='email' :class="['login-input', {'error': isError}]" type='text' @change="isError=false"/>
                    </label>
                    <label>
                        <span :class="{'danger': isError}">Password</span> 
                        <div @click="isShownPassword = !isShownPassword" class="eye-icon">
                            <Icon icon="mdi:eye-off" v-if="!isShownPassword"/>
                            <Icon icon="ic:baseline-remove-red-eye" class="eye-icon-shown" v-else />
                        </div>
                        <input v-model='password' :class="['login-input', {'error': isError}]" :type="isShownPassword ? 'text' : 'password'" @change="isError=false">
                    </label>
                    <LdiButton>Login</LdiButton>
                </form>
            </div>
            <div v-if="authMethod === 'google' || authMethod === 'both'" class='login-block'>
                <div class='block'>
                    <a :href='this.getGoogleAuthUrl()' class='login-google-link'>
                        <LdiIcon action='google' size='xl' />
                        <b class='login-google-text'>Sign in with Google</b>
                    </a>
                </div>
            </div>
            <div v-if="getAuthError" class="login-block">
                <div class="block">
                    <div class="danger danger--medium">{{ getAuthError }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Icon } from "@iconify/vue";

import Utils from '@/Packages/utils'
import LdiButton from '@/Atoms/LdiButton.vue'
import LdiIcon from '@/Atoms/LdiIcon.vue'

export default {
    name: 'LoginComponent',
    components: { LdiIcon, LdiButton, Icon },
    data() {
        return {
            email: '',
            password: '',
            authMethod: null,
            isError: false,
            isShownPassword: false,
        }
    },

    mounted() {
        this.authMethod = process.env.VUE_APP_AUTH_METHOD
    },

    methods: {
        ...mapActions(['login', 'logout']),

        getGoogleAuthUrl() {
            return Utils.google.getAuthUrl('/')
        },
        authorizationViaEmailPassword() {
            this.login({ email: this.email, password: this.password })
                .then(() =>  Utils.auth.redirectAfterAuth(this.$router))
                .catch((e) => {
                    if (e.message) {
                        this.isError = true
                        this.password = ''
                    }
                })
        }
    },
    computed: {
        ...mapGetters(['getAuthError'])
    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss//base/mixins.scss";

.error{
    &-message{
        position: absolute; 
        right: 0.5rem; 
        top: 0.5rem;
        z-index: 200;
        
        @include bp(md) {
            right: 0.75rem; 
            top: 0.75rem;  
        }
    }
}
.eye{
    &-icon{
        position: absolute;
        bottom: var(--form-inputs-padding);
        right: var(--form-inputs-padding);
        cursor: pointer;
        z-index: 200;
        &:hover{
            &>svg{
                &>path{
                    color: var(--color-background) !important;
                }
            }
        }
        &-shown{
            &>path{
                color: var(--color-background);
            }
        }
    }
}
</style>