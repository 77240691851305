<template>
    <create-user-configurator pageName="user" :createUser="createUser" :loadUsers="loadUsers"
        :getUsers="getUsers"/>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapActions: mapActionsUsers, mapGetters: mapGettersUsers } = createNamespacedHelpers('users')

import CreateUserConfigurator from './Components/CreateUserConfigurator.vue'

export default {
    components: { CreateUserConfigurator },

    mounted() {
        this.loadUsersListRoles()
    },

    computed: {
        ...mapGettersUsers(['getUsers'])
    },

    methods: {
        ...mapActions(['loadUsersListRoles']),
        ...mapActionsUsers(['createUser', 'loadUsers']),
    }
}
</script>