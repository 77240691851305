import Api from '../../Packages/api'

export default {
    namespaced: true,

    state: {
        buyers: [],
    },

    getters: {
        getBuyers: state => state.buyers,
    },

    mutations: {
        setBuyers(state, data) {
            state.buyers = data
        },
    },

    actions: {
        loadBuyers({commit}) {
            Api.octopus.analytic.v1.buyers.all()
                .then(data => commit('setBuyers', data))
        },

        loadBuyer({commit}, id) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.buyers.read(id)
                    .then(resolve)
                    .catch(reject)
            })
        },

        createBuyer({commit}, data) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.buyers.create(data)
                    .then(resolve)
                    .catch(reject)
            })
        },

        updateBuyer({commit}, {id, data}) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.buyers.update(id, data)
                    .then(resolve)
                    .catch(reject)
            })
        },

        deleteBuyer({commit}, id){
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.buyers.deleteBuyer(id)
                    .then(resolve)
                    .catch(reject)
            })
        },

        switchAccessBuyer({commit}, payload) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.buyers.switchAccess(payload)
                    .then(resolve)
                    .catch(reject)
            })
        },

        attachApps({commit}, payload) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.buyers.attachApps(payload)
                    .then(resolve)
                    .catch(reject)
            })
        },

        detachApp({commit}, payload) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.buyers.detachApp(payload)
                    .then(resolve)
                    .catch(reject)
            })
        },

    },
}