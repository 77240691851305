import { deleteRequest, getRequest, postRequest, putRequest } from '../octopusAnalyticRequests'

const uri = 'users'

export const create = ({name, email, hasAccess, roles, password, appIds, isQa}) =>
    postRequest(uri, {name, email, has_access: hasAccess, roles, password, appIds, isQa})

export const read = (id) => getRequest(uri + `/${id}`)

export const deleteUser = id => deleteRequest(uri + `/${id}`)

export const update = (id, {name, email, hasAccess, roles, password, appIds, isQa}) =>
    putRequest(uri + `/${id}`, {name, email, hasAccess, roles, password, appIds, isQa})

export const switchAccess = ({id, hasAccess}) => putRequest(uri + `/${id}/switch-access`, {hasAccess})

export const all = () => getRequest(uri)

export const attachApps = ({id, appIds}) => postRequest(uri + `/${id}/attach-apps`, {appIds})

export const detachApp = ({id, appIds}) => deleteRequest(uri + `/${id}/detach-app`, {appIds})