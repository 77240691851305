<template>
    <UsersLdiPage>
        <LdiTabs :tab-names="$options.TABS" 
            :initialActiveTabName="this.$route.query.startTabName">
            <template v-slot="fromSlot">
                <LdiTab v-bind="fromSlot" :name="$options.TABS[0]">
                    <users-tab :users="getUsers" page="user" :loadUsers="loadUsers"
                    :switchAccessUser="switchAccessUser" :deleteUser="deleteUser"/>
                </LdiTab>
                <LdiTab v-bind="fromSlot" :name="$options.TABS[1]">
                    <users-tab :users="getBuyers" page="buyer" :loadUsers="loadBuyers"
                    :switchAccessUser="switchAccessBuyer" :deleteUser="deleteBuyer"/>
                </LdiTab>
            </template>
        </LdiTabs>
    </UsersLdiPage>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters: mapUsersGetters, mapActions: mapUsersActions } = createNamespacedHelpers('users')
const { mapGetters: mapBuyersGetters, mapActions: mapBuyersActions } = createNamespacedHelpers('buyers')

import { auth } from '@/Packages/utils'
import LdiTabs from '@/Atoms/Tab/LdiTabs.vue'
import LdiTab from '@/Atoms/Tab/LdiTab.vue'

import UsersLdiPage from '../../Pages/UsersLdiPage.vue'
import UsersTab from './Tabs/UsersTab.vue'

export default {
    TABS: ['Users', 'Buyers'], 
    components: { UsersLdiPage, UsersTab, LdiTabs, LdiTab, },
    data: () => ({
        isShownColumn: true,
        isShowDeleteConfirm: false,
        idToRemove: null,
        userNameToRemove: null,
    }),
    mounted() {
        this.loadUsers()
        this.loadBuyers()
        if (!this.userCan("manager_user_deactivate")) {
            this.isShownColumn = false
        }
    },
    computed: {
        ...mapUsersGetters(['getUsers']),
        ...mapBuyersGetters(['getBuyers']),
    },
    methods: {
        userCan: auth.isUserCan,

        ...mapUsersActions(['loadUsers', 'deleteUser', 'switchAccessUser']),
        ...mapBuyersActions(['loadBuyers', 'deleteBuyer', 'switchAccessBuyer']),
    }
}
</script>