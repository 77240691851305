<template>
    <article class="tabs" :class="getClasses" @onChange="getTabName">
        <header>
            <ul class="tabs__navigation">
                <li v-for="(tabName, index) in tabNames" :key="index"
                    :class="['tabs__navigation-item', {'tabs__navigation-item--active' : tabName === activeTabName}]"
                    @click="changeActiveTab(tabName)">
                    {{ tabName }}
                </li>
            </ul>
        </header>
        <section class="tabs__content scroll-thin">
            <slot :activeTabName="activeTabName"></slot>
        </section>
    </article>
</template>

<script>

export default {
    name: "LdiTabs",
    props: {
        tabNames: {
            type: Array,
            required: true,
            validator: value => value.length && !value.some(tabName => typeof tabName !== 'string')
        },
        initialActiveTabName: String,
        disableFullHeight: Boolean
    },
    data: () => ({
        activeTabName: null,
    }),
    created() {
        
        if (this.initialActiveTabName) {
            this.activeTabName = this.initialActiveTabName
        } else {
            this.activeTabName = this.tabNames[0]
        }
    },
    methods: {
        changeActiveTab(newActiveTabNAme) {
            this.activeTabName = newActiveTabNAme
            this.getTabName(newActiveTabNAme)
        },
        getTabName(name) {
            this.$router.push({ query: { startTabName: name } })
        }
    },

    computed:{
        getClasses(){
            return {
                'tabs--flex0': this.disableFullHeight
            }
        }
    },
    mounted() {
        this.getTabName(this.activeTabName)
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base/mixins';

.tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    &--flex0{
        flex: 0;
    }

    &__navigation {
        display: flex;
        gap: 0.5rem;
        align-items: flex-end;
        flex-wrap: wrap;

        &-item {
            width: fit-content;
            min-width: var(--form-inputs-extra-small-width);
            height: fit-content;
            padding: .25rem 0.375rem;
            @include fontHeight(1rem, 1.125rem);
            border: 2px solid var(--border-color);
            border-bottom-color: var(--border-color);
            border-radius: var(--border-radius) var(--border-radius) 0 0;
            text-align: center;
            color: inherit;
            background-color: var(--border-color);
            cursor: pointer;
            transform: translateY(0);
            transition: .2s transform ease, .2s color ease;
            z-index: 1;

            &:hover {
                color: inherit;
            }

            &--active {
                padding-top: .5rem;
                font-weight: 500;
                transform: translateY(2px);
                color: inherit;
                border-color: var(--border-color);
                border-bottom: 2px solid var(--color-background);
                background-color: var(--color-background);
                position: relative;
                z-index: 3;

                &:first-child:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transform: translate(-100%, 100%);
                    width: 2px;
                    height: 2px;
                    background-color: var(--border-color);
                }
            }
        }
    }

    &__content {
        z-index: 2;
        display: flex;
        flex-direction: column;
        border: 2px solid var(--border-color);
        flex: 1;
        padding: 0.5rem;
        color: var(--gray-cold);
        background-color: var(--color-background);
        overflow: auto;
        @include bp(md){
            padding: 1rem;
        }
    }
}
</style>