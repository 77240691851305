<template>
    <LdiPage :breadcrumbs="breadcrumbs" :is-loading="this.getIsLoading"
             breadcrumbs-icon="fingerprint" page-title="Roles" page-route-name="roles">
        <slot></slot>
    </LdiPage>
</template>

<script>
import LdiPage from "@/Templates/LdiPage.vue";
import {mapGetters} from "vuex";

export default {
    components: {LdiPage},
    props: {
        breadcrumbs: Object | Array | null
    },
    computed: {
        ...mapGetters(['getIsLoading'])
    }
}
</script>