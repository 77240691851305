<template>
    <ServersLdiPage :breadcrumbs="{text: 'Create server'}">

        <ServerConfigurator @onConfigured='showConfirmPopup' />

        <LdiPrompt
            :is-show='isShowConfirmPopup'
            :messages='["Are you sure?", "Please check all data TWICE!"]'
            @onCancel='closeConfirmPopup' @onConfirm='create'
        />

    </ServersLdiPage>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ServersLdiPage from '@/Pages/ServersLdiPage'
import ServerConfigurator from '@/Components/Servers/Component/ServerConfigurator'
import LdiPrompt from '@/Organisms/Popup/LdiPrompt'

const { mapActions, mapGetters } = createNamespacedHelpers('servers')


export default {
    components: { LdiPrompt, ServerConfigurator, ServersLdiPage },
    data: () => ({
        isShowConfirmPopup: false,
        configuredObject: null
    }),

    methods: {
        ...mapActions(['createServer']),
        create() {
            this.createServer(this.configuredObject)
                .finally(() => this.$router.go(-1))
        },

        showConfirmPopup(configuredObject) {
            this.isShowConfirmPopup = true
            this.configuredObject = configuredObject
            this.$emit('configuredObject', this.configuredObject)
        },

        closeConfirmPopup() {
            this.isShowConfirmPopup = false
            this.configuredObject = null
        }
    }
}
</script>