<template>
    <div v-if="lastPage!==1" class="pagination">
        <ul class="pagination-list">
            <li v-for="link in links"
                :class="['pagination-item', { 'pagination-item--active': link.active }]"
                @click="emitOnPaginate(link.label)">
                <template v-if="link.label.toLowerCase().includes('previous')">
                    <Icon icon="ant-design:arrow-left-outlined"/>
                </template>
                <template v-else-if="link.label.toLowerCase().includes('next')">
                    <Icon icon="ant-design:arrow-right-outlined"/>
                </template>
                <template v-else>{{ link.label }}</template>
            </li>
        </ul>
    </div>
</template>

<script>
import {Icon} from "@iconify/vue";

export default {
    props: {
        links: {
            type: Array,
        },
        currentPage: {
            type: Number,
        },
        lastPage: {
            type: Number,
        },
    },
    components: {
        Icon
    },
    emits: ['onPaginate'],
    methods: {
        emitOnPaginate(page) {
            if (page.toLowerCase().includes('previous')) {
                page = this.currentPage - 1
            } else if (page.toLowerCase().includes('next') && this.currentPage < this.lastPage) {
                page = this.currentPage + 1
            }
            this.$emit('onPaginate', page)
        },
    }
}
</script>