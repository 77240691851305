<template>
    <div class="scroll-thin" style="overflow-x: auto">
        <LdiPaginatedTable 
            :paginated="paginated"
            :rowHeaderCellsConfiguration="getRowHeaderCellsConfiguration()"
            @onPaginate='emitOnPaginate'>

            <tr v-for="stream in paginated?.data" class="streams-tr" :key="stream.uid">
                <td style="background: var(--color-background);">
                    <CopyableText :text="stream.uid" noWrap />
                </td>
                <td style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis; max-width: 350px;">
                    {{ stream.name ?? '-' }}
                </td>
                <td>{{ stream.rules.country.join(', ') ?? '-' }}</td>
                <td>
                    <LdiLabel :label="stream.offer" isNoBorder>
                        <CopyableText :text="stream.offer" :shortText="formattedLink(stream.offer)" isHidden noWrap/>
                    </LdiLabel>
                </td>
                <td>{{ stream.level_cloaking ?? '-' }}</td>
                <td>{{ date(stream.created_at) ?? '-' }}</td>
                <td>{{ date(stream.updated_at) ?? '-' }}</td>
            </tr>
        </LdiPaginatedTable>
    </div>
</template>

<script>
import LdiPaginatedTable from "@/Organisms/Table/LdiPaginatedTable";
import LdiLabel from "@/Atoms/Label/LdiLabel";
import LdiButton from "@/Atoms/LdiButton";
import CopyableText from "@/Molecules/Text/CopyableText";
import VerticalIcon from "@/Components/Users/Icon/VerticalIcon";
import { date } from '@/Packages/utils'

export default {
    emits: ['onPaginate'],

    components: { 
        VerticalIcon, CopyableText, LdiButton, LdiLabel, LdiPaginatedTable },
    props: {
        paginated: {
            type: Object
        },
    },
    methods: {
        getRowHeaderCellsConfiguration() {
            return [
                {
                    cellName: "#",
                    isOrderAble: false
                },
                {
                    cellName: "Stream name",
                    isOrderAble: false
                },
                {
                    cellName: "Country",
                    isOrderAble: false
                },
                {
                    cellName: "Offer",
                    isOrderAble: false
                },
                {
                    cellName: 'Level cloaking',
                    isOrderAble: false
                },
                {
                    cellName: 'Created',
                    isOrderAble: false
                },
                {
                    cellName: 'Updated',
                    isOrderAble: false
                },
            ];
        },
        date(value) {
            return date.datetimeDefault(value)
        },
        formattedLink(url){
            const regex = /https?:\/\/([a-zA-Z0-9.-]+)\//;
            const matches = url?.match(regex) ?? '-';
            const base_url = matches[1] ?? url;
            return base_url
        },
        emitOnPaginate(page) {
            this.$emit('onPaginate', page)
        },
    }
};
</script>

<style lang="scss" scoped>
.streams-tr{
    &>td{
        background: var(--color-background);
        cursor: pointer;
    }
}
</style>