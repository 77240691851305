import {createLogger, createStore} from "vuex";
import apps from "./modules/apps";
import auth from "./modules/auth";
import permissions from "./modules/permissions";
import roles from "./modules/roles";
import theme from "./modules/theme";
import servers from "./modules/servers";
import users from "./modules/users";
import buyers from "./modules/buyers";
import lists from "./modules/lists";
import postbacks from "./modules/postbacks";

const debug = process.env.VUE_APP_STORE_IS_DEBUG === 'true' && process.env.NODE_ENV !== 'production'

export default createStore({
    state: {
        isLoading: false,
        notifications: [],
    },
    getters: {
        getIsLoading: state => state.isLoading,
        getNotifications: state => state.notifications,
    },
    mutations: {
        setIsLoading: (state, value) => {
            state.isLoading = value
        },
        addNotification: (state, value) => {
            state.notifications.push(value)
        },
        notifyError: (state, error) => {
            state.notifications.push({
                action: 'alert',
                message: error,
                type: 'danger',
                timestamp: new Date(),
                closeOnClick: true,
            })
        },
        removeNotification: (state, timestamp) => {
            const indexToDelete = state.notifications.findIndex(n => n.timestamp === timestamp);
            if (indexToDelete !== -1) {
                state.notifications.splice(indexToDelete, 1);
            }
        },
        clearAll: (state) => {
            state.notifications = []
        },
    },

    modules: {
        apps,
        auth,
        permissions,
        roles,
        theme,
        users,
        buyers,
        servers,
        lists,
        postbacks,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})