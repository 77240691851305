import axios from "axios";
import {IS_NEED_PRINT_LOGS} from "./config";
import Api from './index'

//todo подумай как применить к этой писанине
//axios.interceptors.request.use()

axios.interceptors.response.use(
    response => Api.config.useResponseMiddlewares(response),
    error => Api.config.useErrorMiddlewares(error)
)

export default ({
                    url = '/',
                    method = 'get',
                    params = {},
                    data = {},
                    headers = {},
                    responseType = ''
                }) =>
    new Promise((resolve, reject) => {

        const config = Api.config.useRequestMiddlewares({url, method, headers, params, data, responseType})

        axios(config)
            .then(response => {
                if (!response) {
                    reject({message: 'Something went wrong response UNDEFINED'})
                    return
                }

                if (204 === response.status) {
                    resolve({message: 'Done!'})
                }
                if (response?.headers['content-type'].includes('application/json')) {

                    if (response.data.success) {
                        resolve(response.data.data)
                    }
                } else {
                    resolve(response)
                }

            })
            .catch(error => {
                if (IS_NEED_PRINT_LOGS) {
                    console.log("axios->catch error", error)
                }
                reject({message: 'Something went wrong'})
            })
    })