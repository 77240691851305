import { postRequest, postRequestMultipart } from '../octopusAnalyticRequests'

const uri = 'app'

export const create = ({ domain, serverName }) =>
    postRequest(uri + '/add-domain', { domain, serverName })

export const updateFB = ({ appId, configuration }) => {
    const formData = new FormData()
    formData.append('appId', appId)
    formData.append('configuration', configuration)

    return postRequestMultipart(uri + '/firebase-configuration', formData)
}