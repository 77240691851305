import config from "./config";

import * as apps from './analytic/v1/apps'
import * as app from './analytic/v1/app'
import * as google from './analytic/v1/google'
import * as roles from './analytic/v1/roles'
import * as sanctum from './analytic/v1/sanctum'
import * as servers from './analytic/v1/servers'
import * as users from './analytic/v1/users'
import * as buyers from './analytic/v1/buyers'
import * as lists from './analytic/v1/lists'

export default {
    config,
    octopus:{
        analytic:{
            v1:{
                apps,
                app,
                google,
                roles,
                sanctum,
                servers,
                users,
                buyers,
                lists,
            }
        }
    }
}