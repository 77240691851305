import makeRequest from "./makeRequest";
import {request} from '../utils'


export default () => ({
    setApiUrl: function (apiUrl) {
        this._apiUrl = apiUrl
        return this
    },

    postRequest(uri, data, headers, responseType) {
        return makeRequest({url: this._apiUrl + uri, method: 'POST', data, headers, responseType})
    },

    getRequest(uri, params, headers, responseType) {
        return makeRequest({url: this._apiUrl + uri, method: 'GET', params, headers, responseType})
    },
    
    getBuyerRequest(uri, params, headers, responseType) {
        const buyerUrl = this._apiUrl.replace('api/', 'buyer/api/');
        return makeRequest({url: buyerUrl + uri, method: 'GET', params, headers, responseType})
    },

    putRequest(uri, data, headers, responseType) {
        return makeRequest({url: this._apiUrl + uri, method: 'PUT', data, headers, responseType})
    },

    deleteRequest(url, data) {
        return makeRequest({url: this._apiUrl + url, method: "DELETE", data})
    },

    postRequestMultipart(uri, formData) {
        return this.postRequest(uri, formData, {'Content-Type': 'multipart/form-data'})
    },

    putRequestMultipart(uri, formData) {
        return this.postRequest(uri, formData, {
            'Content-Type': 'multipart/form-data',
            'X-HTTP-METHOD-OVERRIDE': 'PUT'
        })
    },

    postRequestDownload(uri, data) {
        return this.postRequest(uri, data, undefined, 'blob')
    },

    getRequestDownload(uri) {
        return this.getRequest(uri, undefined, undefined, 'blob')
    },

    downloadFile(blobPartsOfFile, fileName, fileType) {
        request.downloadFile(blobPartsOfFile, request.generateFileNameWithDate(fileName, fileType))
    }
})