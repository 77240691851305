import storage from "../types/localStorage";

const module = 'date'
const read = storage.read(module)
const write = storage.write(module)
const remove = storage.remove(module)

export const getDate = () => read('startDate') ?? ""
export const setDate = (tokenObject) => write('startDate', tokenObject)
export const removeDate = () => remove('startDate')

