<template>
    <div class="row row--ai-center" :class="{'row--nowrap': noWrap}">
        <LdiIcon :color="colorIcon" action="clipboard-copy" @click="copyToClipboard"/>
        <span v-if="!isHidden" :class="getPathClasses">{{ text }}</span>
        <div v-else>
            <span style="display: none;">{{ text }}</span>
            <span :class="getPathClasses">{{ shortText }}</span>
        </div>
    </div>
</template>

<script>
import LdiIcon from "@/Atoms/LdiIcon.vue";

export default {
    components: {LdiIcon},
    props: {
        text: {
            type: String,
        },
        shortText:{
            type: String,
        },
        colorIcon: {
            type: String,
            default: 'primary',
            validator(color) {
                return ['primary', 'secondary', 'success', 'info', 'warning', 'danger'].includes(color)
            }
        },
        noWrap: Boolean,
        isHidden: Boolean
    },

    data: () => ({
        isCopied: false,
    }),

    methods: {
        copyToClipboard() {
            this.isCopied = true
            navigator.clipboard.writeText(this.text)
                .then(() => setTimeout(() => this.isCopied = false, 300))
        },
    },

    computed: {
        getPathClasses() {
            return {
                'underline-gradient': true,
                ['underline-gradient--' + this.colorIcon]: true,
                'underline-gradient--active': this.isCopied,
            }
        },
    }
}
</script>