<template>
    <div ref='box' class="box">
        <div :class='getClasses' :style='getStyles'>
            <LdiIcon action='close' size='l' style='align-self: flex-end' @click='emitOnHide' />
            <VueJsonPretty :data="JSON.parse(json)" showIcon :showLine="false" showDoubleQuotes 
                showKeyValueSpace collapsedOnClickBrackets :deep="4" class="scroll-thin"
                style="padding-bottom: 3rem;"
                    :style="isMore ? 'overflow-y: scroll;' : ''"
                    />
        </div>
    </div>
</template>

<script>
import LdiIcon from '@/Atoms/LdiIcon'
import { str } from '@/Packages/utils'
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: { LdiIcon, VueJsonPretty },
    inject: ['tableRightPosition'],
    emits: ['onHide'],
    props: {
        jsonFrom: {
            type: String,
            validator(val) {
                return ['request', 'response', 'error'].includes(val)
            }
        },
        json: JSON,
        isMore: Boolean
    },
    data: () => ({
        isFixed: false,
    }),
    methods: {
        ucFirst(string) {
            return str.ucFirst(string)
        },

        emitOnHide() {
            this.$emit('onHide')
        },
        closeKeydownEscape(event) {
            if (event.key === 'Escape') {
                this.emitOnHide()
            }
        },
        watchScroll() {
            if (!this.isFixed && this.$refs.box.getBoundingClientRect().top <= 0) {
                this.isFixed = true
                return
            }
            
            if (this.isFixed && this.$refs.box.getBoundingClientRect().top > 0) {
                this.isFixed = false 
            }
        }
    },

    mounted() {
        if (this.$refs.box.top <= 0) {
            this.isFixed = true
        }
        document.addEventListener('scroll', this.watchScroll)
        document.addEventListener('keydown', this.closeKeydownEscape)
    },

    beforeUnmount() {
        document.removeEventListener('scroll', this.watchScroll)
        document.removeEventListener('keydown', this.closeKeydownEscape)
    },

    computed: {
        getStyles() {
            // console.log(this.tableRightPosition);
            return this.isFixed
                ? {
                    left: this.tableRightPosition + 'px'
                }
                : null
        },

        getClasses() {
            return {
                'box-fixed': this.isFixed,
                'column': true,
                'scroll-thin': true
            }
        },

        getClassesTitle() {
            return {
                'title': true,
                'success': this.jsonFrom === 'request',
                'success--medium': this.jsonFrom === 'request',
                'primary': this.jsonFrom === 'response',
                'primary--medium': this.jsonFrom === 'response',
                'danger': this.jsonFrom === 'error',
                'danger--medium': this.jsonFrom === 'error'
            }
        },
    }
}
</script>

<style lang='scss' scoped>
@import '../../../assets/scss/base/mixins';

.box {
    overflow: scroll;
    min-width: 300px;
    @include bp(xl){
        min-width: 500px;

    }
    &-fixed {
        width: auto;
        position: fixed;
        top: 0;
        bottom: 52px;
        right: 36px;
        padding: 1rem 2rem 1rem 1rem;
    }
}

.title {
    font-size: 1.5rem;
}

</style>