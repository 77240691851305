import * as auth from './types/auth'
import * as google from './types/google'
import * as media from './types/media'
import * as object from './types/object'
import * as request from './types/request'
import * as str from './types/str'
import * as number from './types/number'
import * as date from './types/date'

export default {
    auth,
    google,
    media,
    object,
    request,
    str,
    number,
    date,
}

export {auth}
export {google}
export {media}
export {object}
export {request}
export {str}
export {number}
export {date}