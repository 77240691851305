<template>
    <div class='column'>
        <LdiButton :disabled='!isEnableBtn' action='save' style='align-self: flex-end' icon @click='emitOnRemove' />
        <div class='partner-networks__content' v-if="sourceIds?.length && getUserApps?.length">
            <table>
                <thead>
                <tr>
                    <th scope='col'>Bundle (name)</th>
                    <th scope='col'>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='userApp in getUserApps' :key='userApp.id'>
                    <td>{{ userApp.label }}</td>
                    <td>
                        <LdiButton action='remove' icon size='xs' @click='removeApp(userApp.id)' />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-else> User {{ name }} has no available apps</div>
    </div>
</template>

<script>
import LdiIcon from '@/Atoms/LdiIcon'
import LdiButton from '@/Atoms/LdiButton'
import { mapGetters } from 'vuex'

export default {
    emits: [ 'onRemove'],
    components: { LdiButton, LdiIcon },
    props: {
        sourceIds: {
            type: Array,
            default: []
        },
        name: String
    },
    data: () => ({
        deletedApps: [],
        isEnableBtn: false,
    }),
    methods: {
        emitOnRemove() {
            this.$emit('onRemove', {appIds: this.deletedApps})
            this.isEnableBtn = false
        },
        removeApp(id) {
            this.deletedApps.push(id)
            this.isEnableBtn = true
        },
    },
    computed: {
        ...mapGetters(['getListApps']),
        getUserApps() {
            return this.getListApps.filter(item => this.sourceIds.includes(item.id) && !this.deletedApps.includes(item.id))
        }
    }
}
</script>

<style scoped>
.v-select {
    width: 100%;
    margin: 0;
}
tr{
    cursor: pointer;
}
</style>