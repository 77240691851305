<template>
    <label class='column'>
        <slot></slot>
        <input v-if="canEditDomain" v-model='name' :placeholder="placeholder" type='text'
        @input="checkIsDataUsed(field)" :class="{'error': isShowErrorMessage[field]}">
        <span v-if="isShowErrorMessage[field]" class="danger">{{errorMessage[field]}}</span>
    </label>
</template>

<script>
import { str } from '@/Packages/utils'
import { mapActions, mapGetters } from 'vuex'
export default {
        emits: ['onUpdate', 'update:modelValue'],
        name: "ValidatedField",
        data: () => ({
            errorMessage: {},
            isShowErrorMessage: {},
        }),
        props:{
            canEditDomain: {
                type: Boolean,
                default: true
            },
            source: Object,
            field: String,
            placeholder: String,
            modelValue: String,
        },
        methods: {
            hasSpacesAtStartOrEnd(str) {
                return str.trim() !== str;
            },
            hasSpaces(str) {
                return str.includes(' ') || str.includes('\t') || str.includes('\n');
            },

            checkIsDataUsed(data){
                if (this.modelValue?.trim()
                    !== this.source?.[data]
                    && this[`apps/get${str.ucFirst(data)}s`]
                    && this[`apps/get${str.ucFirst(data)}s`].includes(this.modelValue?.trim())) {
                    this.errorMessage[data] = `The ${data} ${this.modelValue} was previously used`
                    this.isShowErrorMessage[data] = true
                } else{
                    this.isShowErrorMessage[data] = false
                }
                if (this.field === 'name' && this.hasSpacesAtStartOrEnd(this.modelValue)) {
                    this.errorMessage[data] = `Check the ${data} ${this.modelValue} for spaces`;
                    this.isShowErrorMessage[data] = true
                }
                if ((this.field === "name" && this.hasSpacesAtStartOrEnd(this.modelValue)) ||
                    (this.field !== "name" && this.hasSpaces(this.modelValue))) {
                    this.errorMessage[data] = `Check the ${data} ${this.modelValue} for spaces`;
                    this.isShowErrorMessage[data] = true;
                }
                this.$emit('onUpdate', {...this.isShowErrorMessage});
            }
        },
        computed: {
            ...mapGetters(['apps/getDomains', 'apps/getBundles', 'apps/getNames']),
            name: {
              get() {
                return this.modelValue;
              },
              set(value) {
                this.$emit('update:modelValue', value);
              }
            }
        },
    }
</script>

<style lang="scss" scoped>
.errorMessage{
    position: absolute; 
    right: 0;
}
</style>