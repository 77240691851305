export const datetimeDefault = (date = null) => datetimeFormat(date)

const datetimeFormat = (date = null, format = null) => null === date ? date : new Date(date).toLocaleString()

export const dateDefault = (date = null) => dateFormat(date)

const dateFormat = (date = null, format = null) => null === date ? date : new Date(date).toLocaleDateString()

export const timeDefault = (date = null) => timeFormat(date)

const timeFormat = (date = null, format = null) => null === date ? date : new Date(date).toLocaleTimeString()
