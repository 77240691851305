<template>
    <create-role-configurator :loadListPermissions="loadUsersListPermissions" pageName="user"/>
</template>

<script>
import {mapActions} from "vuex";
import CreateRoleConfigurator from "./Components/CreateRoleConfigurator.vue";

export default {
    components: { CreateRoleConfigurator },

    methods: {
        ...mapActions(['loadUsersListPermissions']),
    }
}
</script>