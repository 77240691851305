<template>
    <UsersLdiPage :breadcrumbs="{to: '/users/add', text: `Create ${pageName}` }">
        <div class='block-flex'>
            <label class='column'>
                <span :class="{ 'danger': isError['name'] }">Name</span>
                <input ref='inputName' v-model='name' placeholder='John' type='text' :class="[{ 'error': isError['name'] }]" 
                    @input="nameValidation">
                <span v-if="isError['name']" class="danger">{{ errorMessage['name'] }}</span>
            </label>
            <label class='column'>
                <span :class="{'danger': isError['email']}">Email</span>
                <input v-model='email' placeholder='example@email.com' type='email' :class="[{'error': isError['email']}]" 
                    @input="emailValidation">
                <span v-if="isError['email']" class="danger">{{ errorMessage['email'] }}</span>
            </label>
        </div>
        <div class='block-flex'>
            <label class='column'>
                <span :class="{'danger': isError['password']}">Password</span>
                <input v-model='password' autocomplete='off' placeholder='Password' type='text' :class="[{'error': isError['password']}]" @input="passwordValidation">
                <span v-if="isError['password']" class="danger">*Minimum length of 8 characters, must contain at least one digit, at least one letter in lower and uppercase</span>
            </label>
        </div>
        <label class='label-flex'>
            <input v-model='hasAccess' type='checkbox'>
            Has Access
        </label>
        <v-select v-if="pageName==='buyer'" v-model='appIds' :options='getListApps' :reduce='option => option.id'
                  class='v-select' multiple placeholder='Select apps to bind with user'>
        </v-select>
        <div class='column'>
            <span>Roles</span>
            <label v-for='role in getRolesList' :key='role.id' class='label-flex'>
                <input :id='role.label' v-model='checkedRoles' :value='role.label' type='checkbox'>
                {{ role.label }}
            </label>
        </div>
        <LdiButton action='submit' @click.prevent='addUser' :disabled="isDisabled"/>
    </UsersLdiPage>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LdiButton from '../../../Atoms/LdiButton.vue'
import UsersLdiPage from '../../../Pages/UsersLdiPage.vue'

import {auth} from '@/Packages/utils'

export default {
    components: { UsersLdiPage, LdiButton },
    data: () => ({
        name: '',
        email: '',
        password: '',
        hasAccess: true,
        roles: [],
        checkedRoles: [],
        appIds: [],

        isError: {},
        errorMessage: {},
        isShownColumn: true,
    }),
    props: {
        pageName: String,
        loadUsers: Function,
        createUser: Function,
        getUsers: Object,
    },
    mounted() {
        this.loadListApps()
        this.loadUsers()
        this.$refs.inputName.focus()
        if (!this.userCan("manager_user_deactivate")) {
            this.isShownColumn = false
        }
    },

    computed: {
        ...mapGetters(['getListRoles', 'getListApps']),

        userConfig() {
            return {
                name: this.name,
                email: this.email,
                hasAccess: this.hasAccess,
                roles: this.checkedRoles,
                password: this.password,
                appIds: this.appIds
            }
        },
        isDisabled() {
            return !this.email
                || !this.password
                || !this.name
                || !this.checkedRoles.length
                || Object.entries(this.isError).some(([key, value])=>value)
                || this.isAlreadyExist('name')
                || this.isAlreadyExist('email')
        },
        
        getRolesList(){
            if (!this.isShownColumn) {
                return this.getListRoles.filter(role => role.label === "Buyer");
            } else{
                return this.getListRoles
            }
        }
    },

    methods: {
        userCan: auth.isUserCan,

        ...mapActions(['loadListApps']),

        addUser() {
            this.createUser(this.userConfig)
                .then(() => this.$router.go(-1))
        },
        nameValidation() {
            const namePattern = /^.{3,}$/;
            const existMessage = '*User with this name already exists'
            const invalidMessage = '*Provide a valid name (min length - 3)'
            this.validation(this.name, namePattern, 'name', existMessage, invalidMessage)
        },
        emailValidation(){
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const existMessage = '*User with this email already exists'
            const invalidMessage = '*Provide a valid email address'
            this.validation(this.email, emailPattern, 'email', existMessage, invalidMessage)
        },
        passwordValidation() {
            const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
            this.validation(this.password, passwordPattern, 'password')
        },
        isAlreadyExist(dataName) {
            return this.getUsers?.some((user) => this[dataName].trim() === user[dataName])
        },
        validation(text, regularExpression, inputType, existMessage, invalidMessage) {
            if (regularExpression.test(text)) {
                this.isError[inputType] = false
                if (this.isAlreadyExist(inputType)) {
                    this.errorMessage[inputType] = existMessage
                    this.isError[inputType] = true
                } else {
                    this.isError[inputType] = false
                }
            } else {
                if (this[inputType]) {
                    this.errorMessage[inputType] = invalidMessage
                    this.isError[inputType] = true
                } else {
                    this.isError[inputType] = false
                }
            }
        },   
    }
}
</script>
<style scoped>
.v-select {
    width: 100%;
    margin: 0;
}
</style>