<template>
    <edit-user-configurator :tabs="$options.TABS" isBuyerPage pageName="buyer"
        :loadUser="loadBuyer" :updateUser="updateBuyer" :getUsers="getBuyers"/>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapActions: mapActionsBuyer, mapGetters: mapGettersBuyers } = createNamespacedHelpers('buyers')

import EditUserConfigurator from './Components/EditUserConfigurator.vue'

export default {
    TABS: ['Settings', 'Apps', 'Streams'],
    
    components: { EditUserConfigurator },

    mounted() {
        this.loadBuyersListRoles()
    },
    computed: {
        ...mapGettersBuyers(['getBuyers'])
    },
    methods: {
        ...mapActions(['loadBuyersListRoles',]),
        ...mapActionsBuyer(['loadBuyer', 'updateBuyer']),
    },
}
</script>