<template>
    <div class="column">
        <router-link :to="{ name: `${page}.add` }">
            <LdiButton icon />
        </router-link>
        
        <table class='users-table' v-if="Object.keys(users).length">
            <thead>
                <tr>
                    <th scope='col'>Name</th>
                    <th scope='col'>Email</th>
                    <th scope='col' v-if="isShownColumn"></th>
                    <th scope='col' v-if="isShownColumn"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for='user in users' v-bind:key='user.id' @dblclick='goToUser(user.id)'>
                    <td>{{ user.name }}</td>
                    <td>{{ user.email }}</td>
                    <td v-if="isShownColumn">
                        <LdiIcon action='edit' @click='goToUser(user.id)' />
                    </td>
                    <td v-if="isShownColumn">
                        <LdiIcon action='remove' @click='showRemoveConfirm(user.id, user.name)' />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <LdiPrompt :is-show='isShowDeleteConfirm' :messages='["Are you sure?", `User ${userNameToRemove} will be deleted!`]'
        @onCancel='closeRemoveConfirm' @onConfirm='remove' />
</template>

<script>

import LdiIcon from '../../../Atoms/LdiIcon.vue'
import LdiButton from '../../../Atoms/LdiButton.vue'
import LdiPrompt from '@/Organisms/Popup/LdiPrompt'

import { auth } from '@/Packages/utils'

export default {
    components: { LdiIcon, LdiButton, LdiPrompt },

    data: () => ({
        isShownColumn: true,
        isShowDeleteConfirm: false,
        idToRemove: null,
        userNameToRemove: null,
    }),
    props: {
        users: Object,
        page: String,
        loadUsers: Function,
        switchAccessUser: Function,
        deleteUser: Function,
    },
    mounted() {
        if (!this.userCan("manager_user_deactivate")) {
            this.isShownColumn = false
        }
    },
    methods: {
        userCan: auth.isUserCan,

        goToUser(id) {
            if (this.isShownColumn) {
                this.$router.push({ name: `${this.page}.edit`, params: { id } })
            }
        },

        toggleAccess(id, hasAccess) {
            this.switchAccessUser({ id, hasAccess })
        },

        remove() {
            this.deleteUser(this.idToRemove).finally(() => {
                this.loadUsers()
                this.closeRemoveConfirm()
            })
        },

        showRemoveConfirm(id, name) {
            this.idToRemove = id
            this.userNameToRemove = name
            this.isShowDeleteConfirm = true
        },

        closeRemoveConfirm() {
            this.idToRemove = null
            this.isShowDeleteConfirm = false
        }
    }
}
</script>
<style scoped>
tr {
    cursor: pointer;
}
</style>