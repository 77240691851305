<template>
    <button v-if="loadIndicator" class="btn btn--success" :class="{'load':loadingState}" style="min-width: 110px">
        <template v-if="loadingState">
            Loading
            <LdiEllipsisLoader/>
        </template>
        <template v-else>
            Apply
        </template>
    </button>
    <button v-else :class="getClasses" :disabled="disabled">
        <LdiIcon v-if="icon" :action="action" :color='color' :disabled="disabled" :size="size"/>
        <slot v-else>{{ getText }}</slot>
    </button>
</template>

<script>
import LdiIcon from "./LdiIcon.vue";
import Utils from "../Packages/utils"
import LdiEllipsisLoader from "../Atoms/LdiEllipsisLoader.vue";

export default {
    name: "LdiButton",
    components: {
        LdiEllipsisLoader,
        LdiIcon,
    },
    props: {
        action: {
            type: String,
            default: 'add',
            validator(action) {
                return [
                    'add', 'save', 'submit', 'edit', 'show', 'remove', 'close', 'clear', 'download', 'share', 'restart',
                    'clipboard-copy', 'filter', 'return'
                ].includes(action)
            }
        },
        color: {
            type: String,
            default: null,
            validator(color) {
                return ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'default'].includes(color)
            }
        },
        size: {
            type: String,
            default: 'm',
            validator(size) {
                return ['xs', 's', 'm', 'l', 'xl'].includes(size)
            }
        },
        type: {
            type: String,
            default: 'filled',
            validator(style) {
                return ['filled', 'outline'].includes(style)
            }
        },
        circle: Boolean,
        icon: Boolean,
        round: Boolean,
        full: Boolean,
        disabled: Boolean,
        loadIndicator: Boolean,
        loadingState: Boolean
    },
    computed: {
        getClasses() {
            const classes = ['btn']
            let btnClass = 'btn--' + this.getColorByAction

            if (this.icon) {
                btnClass += '--outline'
                classes.push(btnClass)

                classes.push('btn--type-icon')

                if (this.circle) {
                    classes.push('btn--circle')
                }

                return classes
            } else {
                if (this.type === 'outline') {
                    btnClass += '--outline'
                }
                classes.push(btnClass)

                if (this.round) {
                    classes.push('btn--round')
                }

                if (this.full) {
                    classes.push('btn--full')
                }
            }
            return classes
        },
        getColorByAction() {
            if (this.color) {
                return this.color
            }

            if (['add', 'download', 'show', 'share'].includes(this.action)) {
                return 'primary'
            } else if (['edit', 'close'].includes(this.action)) {
                return 'warning'
            } else if (['save', 'submit', 'return'].includes(this.action)) {
                return 'success'
            } else if (['remove'].includes(this.action)) {
                return 'danger'
            } else if (['clear'].includes(this.action)) {
                return 'secondary'
            }
        },
        getText() {
            return Utils.str.ucFirst(this.action)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/base/mixins';

</style>