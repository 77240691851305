import { deleteRequest, getRequest, postRequest, putRequest } from '../octopusAnalyticRequests'

const uri = 'buyers'

export const all = () => getRequest(uri)

export const create = ({name, email, hasAccess, roles, password, appIds}) =>
    postRequest(uri, {name, email, has_access: hasAccess, roles, password, app_ids: appIds})

export const read = id => getRequest(uri + `/${id}`)

export const update = (id, {name, email, hasAccess, roles, password, appIds}) =>
    putRequest(uri + `/${id}`, {name, email, hasAccess, roles, password, app_ids: appIds})

export const deleteBuyer = id => deleteRequest(uri + `/${id}`)

export const switchAccess = ({id, hasAccess}) => putRequest(uri + `/${id}/switch-access`, {hasAccess})