<template>
    <th :class="getClasses" scope="col" @click="emitOnOrder">
        <div class="row row--nowrap row--ai-center">
            <LdiIcon v-if="isOrderAble && !isShowOrderIcon" action="direction" />
            <LdiIcon v-if="isOrderAble" v-show="isShowOrderIcon" color='primary'
                :action="initIsAscOrderDirection ? 'triangle' : 'triangle-down'" />
            {{ cellName }}
            <LdiCellHeaderTableTooltip :label="tooltip" v-if="tooltip" :lastLabel="lastLabel === cellName">
                <LdiIcon action="info" />
            </LdiCellHeaderTableTooltip>
        </div>
    </th>
</template>

<script>
import LdiIcon from "../../Atoms/LdiIcon";
import LdiCellHeaderTableTooltip from '../../Atoms/LdiCellHeaderTableTooltip.vue'
export default {
    emits: ['onOrder'],
    components: { LdiIcon, LdiCellHeaderTableTooltip },
    props: {
        cellName: String,

        isOrderAble: Boolean,
        cellNameOrderAble: String,
        isShowOrderIcon: Boolean,

        //для размеров колонки
        isDateTime: Boolean,

        initIsAscOrderDirection: {
            type: Boolean,
            defaults: false
        },

        tooltip: String,

        lastLabel: String,
    },
    methods: {
        emitOnOrder() {
            if (this.isOrderAble) {
                this.$emit('onOrder', this.getOrderByConfiguration)
            }
        }
    },
    computed: {
        getOrderByConfiguration() {
            return {
                orderBy: this.cellNameOrderAble,
            }
        },

        getClasses() {
            return {
                cell: !this.isDateTime,
                dateTime: this.isDateTime
            }
        }
    }
}
</script>