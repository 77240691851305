<template>
    <LdiButton :disabled='isDisabled' action='save' icon style='align-self: flex-end'
               @click='emitOnConfigured'/>

    <div class='column'>
        <label class='column'>
            Name
            <input ref='inputName' v-model='name' placeholder='server name' type='text'>
        </label>
        <label class='column'>
            Url
            <input v-model='url' placeholder='server url' type='url'>
        </label>
        <label class='column'>
            <span class='row row--ai-center'>
                Token
                <LdiIcon action='restart' color='primary' @click='generateToken' />
            </span>
            <CopyableText :text='token' colorIcon='success' />

        </label>
        <label class='column'>
            Region
            <input v-model='region' placeholder='server region' type='text'>
        </label>
    </div>

</template>

<script>
import Utils from '@/Packages/utils'
import LdiButton from '@/Atoms/LdiButton'
import LdiIcon from '@/Atoms/LdiIcon'
import CopyableText from '@/Molecules/Text/CopyableText'

export default {
    emits: ['onConfigured'],

    components: { CopyableText, LdiIcon, LdiButton },

    props: {
        source: Object
    },

    data: () => ({
        name: null,
        url: null,
        token: null,
        region: null,
        startValue: {},
    }),

    computed: {
        isDisabled() {
            return !this.name
                || !this.url
                || !this.token
                || !this.region
                || this.isUnchanged
        },
        isUnchanged() {
            return (
                this.name === this.startValue.name &&
                this.url === this.startValue.url &&
                this.token === this.startValue.token &&
                this.region === this.startValue.region
            )
        },
        getConfiguredObject() {
            return {
                name: this.name,
                url: this.url,
                token: this.token,
                region: this.region
            }
        }
    },

    methods: {
        emitOnConfigured() {
            this.$emit('onConfigured', this.getConfiguredObject)
        },

        generateToken() {
            this.token = Utils.str.generateToken()
        },

        copy() {
            this.isCopied = true
            navigator.clipboard.writeText(this.text)
                .then(() => setTimeout(() => this.isCopied = false, 300))
        }
    },

    mounted() {
        if (this.source) {
            this.name = this.source.name
            this.url = this.source.url
            this.token = this.source.token
            this.region = this.source.region

            this.startValue = {
                name: this.name,
                url: this.url,
                token: this.token,
                region: this.region,
            }
        } else {
            this.generateToken()
        }
    }
}
</script>