import storage from "../types/localStorage";

const module = 'auth'
const read = storage.read(module)
const write = storage.write(module)
const remove = storage.remove(module)

export const getToken = () => JSON.parse(read('token') ?? "{}")
export const setToken = (tokenObject = {}) => write('token', JSON.stringify(tokenObject))
export const removeToken = () => remove('token')

export const getUser = () => JSON.parse(read('user') ?? "{}")
export const setUser = (userObject = {}) => write('user', JSON.stringify(userObject))
export const removeUser = () => remove('user')

export const getRouteLinkRedirectAfterReLogIn = () => read('routeLinkRedirectAfterReLogIn')
export const setRouteLinkRedirectAfterReLogIn = routeName => write('routeLinkRedirectAfterReLogIn', routeName)
export const removeRouteLinkRedirectAfterReLogIn = () => remove('routeLinkRedirectAfterReLogIn')