<template>
    <div class='row row--ai-center' :class="{'row--nowrap': isNoWrap}">
        <template v-if="data?.check_domains?.length">
            <template v-for="check_domain in data?.check_domains" :key="check_domain.id">
                <LdiLabel :label="formattedDate(check_domain.ssl_at)??'no ssl date'" :isNoBorder="isNoBorder">
                    <LdiIcon action="ssl"
                    :color="getColor(check_domain.ssl_at)"/>
                </LdiLabel>
            </template>
        </template>
        <LdiLabel v-else label="no ssl" :isNoBorder="isNoBorder">
            <LdiIcon action="ssl" 
            color="danger"/>
        </LdiLabel>
        <LdiLabel :label="formattedDate(data?.expiration_at)??'date did not add'" :isNoBorder="isNoBorder">
            <LdiIcon action="expire" 
            :color="getColor(data?.expiration_at)"/>
        </LdiLabel>
        <slot></slot>
    </div>
</template>

<script>
import LdiLabel from '@/Atoms/Label/LdiLabel'
import LdiIcon from '@/Atoms/LdiIcon'
import { date } from '@/Packages/utils'

export default {
    name: "ExpirationSslIcons",
    components: { LdiIcon, LdiLabel },
    props: {
        data: {
            type: Object
        },
        isNoBorder: Boolean,
        isNoWrap: Boolean
    },
    methods: {
        getColor(date){
            if (date) {
                const targetDate = new Date(date)?.getTime()
                const twoWeeksInMillis = 2 * 7 * 24 * 60 * 60 * 1000;
                const currentDate = new Date().getTime();
                const timeDifference = targetDate - currentDate;
                return currentDate >= targetDate ? 'danger' : timeDifference < twoWeeksInMillis ? 'warning' : 'success'
            }
            return 'danger'
        },
        formattedDate(value) {
            return date.datetimeDefault(value)
        }
    }
}
</script>