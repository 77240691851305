<template>
    <edit-role-configurator 
        :loadUsersListRoles="loadBuyersListRoles"
        :loadUsersListPermissions="loadBuyersListPermissions"
        pageName="buyer"/>
</template>

<script>
import {mapActions} from "vuex";
import EditRoleConfigurator from './Components/EditRoleConfigurator.vue';

export default {
    components: { EditRoleConfigurator},

    methods: {
        ...mapActions([ 'loadBuyersListPermissions', 'loadBuyersListRoles' ]),
    },
}
</script>