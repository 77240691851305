<template>
    <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<style lang="scss" scoped>
$ell-step-load: 2px;

$ell-width: $ell-step-load*10;
$ell-height: $ell-step-load*3;

$radius-ball: $ell-step-load*1.6;

$ell-top: $ell-step-load*4;
$ell-center-anim: $ell-step-load*2.5;

$ell-child1: $ell-step-load*2;
$ell-child2: $ell-step-load*2;
$ell-child3: $ell-step-load*5;
$ell-child4: $ell-step-load*8;

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: $ell-width;
    height: $ell-height;

    div {
        position: absolute;
        top: $radius-ball;
        width: $radius-ball;
        height: $radius-ball;
        border-radius: 50%;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        background: #fff;

        //.light & {
        //    background: #374151;
        //}

        &:nth-child(1) {
            left: $ell-child1;
            animation: lds-ellipsis1 0.6s infinite;
        }

        &:nth-child(2) {
            left: $ell-child2;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(3) {
            left: $ell-child3;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(4) {
            left: $ell-child4;
            animation: lds-ellipsis3 0.6s infinite;
        }
    }

}


@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate($ell-center-anim, 0);
    }
}

</style>