import {getRequest, postRequest, putRequest} from "../octopusAnalyticRequests";

const uri = 'roles'

export const create = ({name, permissionIds}) =>
    postRequest(uri, {name, permission_ids: permissionIds})

export const read = id => getRequest(uri + `/${id}`)

export const update = ({id, name, permissionIds}) =>
    putRequest(uri + `/${id}`, {name, permission_ids: permissionIds})

export const all = () => getRequest(uri)

export const getPermission = () => getRequest(uri + '/get-permission')