import {getRequest, getBuyerRequest} from "../octopusAnalyticRequests";

export const buyersPermissions = () => getRequest('permissions/buyers/list')
export const usersPermissions = () => getRequest('permissions/users/list')

export const buyersRoles = () => getRequest('roles/buyers/list')
export const usersRoles = () => getRequest('roles/users/list')

export const apps = () => getRequest('apps/list')

export const buyers = () => getRequest('buyers/list')
export const users = () => getRequest('users/list')

export const streams = ({userId, page}) => getRequest(`buyers/${userId}/streams/list`, {page})

export const servers = () => getRequest('servers/list')