export default {
	state: {
		color_theme: localStorage.getItem('color-theme') ?? 'light',
		display_type: 'mob'
	},

	getters: {
		colorTheme: state => state.color_theme,
		isMob: state => state.display_type !== 'pc',
		isPc: state => state.display_type === 'pc',
	},

	mutations: {
		changeColorTheme(state) {
			state.color_theme = state.color_theme === 'light' ? 'dark' : 'light'
			localStorage.setItem('color-theme', state.color_theme);
		},
		setColorTheme(state, data) {
			state.color_theme = data
			localStorage.setItem('color-theme', state.color_theme);
		},

		setMob(state) {
			state.display_type = 'mob'
		},
		setPc(state) {
			state.display_type = 'pc'
		},
	},
}