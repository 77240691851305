import RolesPage from "../../../Components/Roles/RolesPage.vue";
import CreateUserRolePage from "../../../Components/Roles/CreateUserRolePage.vue";
import CreateBuyerRolePage from "../../../Components/Roles/CreateBuyerRolePage.vue";

import EditRoleUserPage from "../../../Components/Roles/EditRoleUserPage.vue";
import EditRoleBuyerPage from "../../../Components/Roles/EditRoleBuyerPage.vue";

import UsersPage from "../../../Components/Users/UsersPage.vue";
import CreateUserPage from "../../../Components/Users/CreateUserPage.vue";
import CreateBuyerPage from "../../../Components/Users/CreateBuyerPage.vue";

import EditUserPage from "../../../Components/Users/EditUserPage.vue";
import EditBuyerPage from "../../../Components/Users/EditBuyerPage.vue";


export const users = [
    /**                                                            Roles                       ***************/
    {
        path: '/roles',
        name: 'roles',
        component: RolesPage,
        meta: {
            permissions: [
                "manager_user_create",
            ]
        }
    },
    {
        path: '/roles/create-user',
        name: 'user.roles.create',
        component: CreateUserRolePage,
        meta: {
            permissions: [
                "manager_user_create",
            ]
        }
    },
     {
        path: '/roles/create-buyer',
        name: 'buyer.roles.create',
        component: CreateBuyerRolePage,
        meta: {
            permissions: [
                "manager_user_create",
            ]
        }
    },
    {
        path: '/roles/user/:id/edit',
        name: 'user.roles.edit',
        component: EditRoleUserPage,
        meta: {
            permissions: [
                "manager_user_create",
            ]
        }
    },
    {
        path: '/roles/buyer/:id/edit',
        name: 'buyer.roles.edit',
        component: EditRoleBuyerPage,
        meta: {
            permissions: [
                "manager_user_create",
            ]
        }
    },
    /**                                                            Users                       ***************/
    {
        path: '/users',
        name: 'users',
        component: UsersPage,
        meta: {
            permissions: 
            [
                "manager_user_create",
                "manager_user_buyer_create",
                "manager_user_edit",
                "manager_user_deactivate",
            ]
        }
    },
    {
        path: '/users/add',
        name: 'user.add',
        component: CreateUserPage,
        meta: {
            permissions: [
                "manager_user_create",
                "manager_user_buyer_create",
            ]
        }
    },
    {
        path: '/users/buyers/add',
        name: 'buyer.add',
        component: CreateBuyerPage,
        meta: {
            permissions: [
                "manager_user_create",
                "manager_user_buyer_create",
            ]
        }
    },
    {
        path: '/users/:id/edit',
        name: 'user.edit',
        component: EditUserPage,
        meta: {
            permissions: ["manager_user_edit"],
        }
    },

    {
        path: '/users/buyers/:id/edit',
        name: 'buyer.edit',
        component: EditBuyerPage,
        meta: {
            permissions: ["manager_user_edit"],
        }
    },
]