<template>
    <RolesLdiPage :breadcrumbs="{text: `Create ${pageName} role`}">
        <label class="column">
            Title
            <input ref="inputTitle" v-model="name" placeholder="Manager" type="text">
        </label>
        <div class="column">
            <span>Permissions</span>
            <div v-for="permission in getListPermissions" :key="permission.id">
                <label class="label-flex">
                    <input v-model="checkedPermissions" :value="permission.id" type="checkbox">
                    <strong>{{ permission.label }}</strong> {{ !!permission.description ? ` - ${permission.description}` : '' }}
                </label>
            </div>
        </div>
        <LdiButton action="submit" @click.prevent="create">Create</LdiButton>
    </RolesLdiPage>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LdiButton from "@/Atoms/LdiButton";
import RolesLdiPage from "@/Pages/RolesLdiPage";

export default {
    components: {RolesLdiPage, LdiButton},
    data() {
        return {
            name: '',
            checkedPermissions: [],
        }
    },
    props: {
        loadListPermissions: Function,
        pageName: String
    },
    mounted() {
        this.loadListPermissions()
        this.$refs.inputTitle.focus()
    },
    computed: {
        ...mapGetters(['getListPermissions']),
    },
    methods: {
        ...mapActions(['createRole']),
        create() {
            this.createRole({name: this.name, permissionIds: this.checkedPermissions})
                .finally(() => this.$router.go(-1))
        },
    }
}
</script>