<template>
    <header class="header">
        <button id="theme-toggle" class="header-themeBtn" type="button">
            <Icon :icon="colorTheme === 'light' ? 'bi:moon-fill' : 'bi:sun-fill'" class="icon"
                  @click="changeColorTheme"/>
        </button>

        <button ref="top_menu_btn" class="header-avatar" @click="menuToggle">
            <img class="header-avatar-img" 
                :src="'https://ui-avatars.com/api/?name='+showUserName+'&background='+(colorTheme === 'light' ? '2563eb': '509fe9')+'&bold=true&color=fff'" 
                alt="avatar">
            <Icon :icon="!menu? 'codicon:triangle-down' : 'codicon:triangle-up'"/>
        </button>

        <transition name="fade">
            <div v-if="menu" id="dropdownSmall" ref="top_menu" class="header-dropbox">
                <div class="header-logged header-logged-as">
                    <span>Logged As</span>
                    <span class="header-logged-name">{{ showUserName }}</span>
                </div>
                <div class="header-logged hoverable is-select" @click="signOut">
                    <span class="header-logged-name">Sign out</span>
                </div>
            </div>
        </transition>
    </header>
</template>

<script>
import {Icon} from "@iconify/vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    components: {
        Icon,
    },
    data() {
        return {
            menu: false,
        };
    },

    watch: {
        menu(state) {
            if (state) {
                document.addEventListener('click', this.handlerCloseTopMenu, true)
            } else {
                document.removeEventListener('click', this.handlerCloseTopMenu, true)
            }
        }
    },

    methods: {
        ...mapActions(['logout']),
        ...mapMutations(['changeColorTheme']),

        signOut() {
            this.logout().then(() => this.$router.push('/login'))
        },

        menuToggle(e) {
            this.menu = !this.menu;
        },

        handlerCloseTopMenu(e) {
            if (!(
                e.composedPath().includes(this.$refs.top_menu) ||
                e.composedPath().includes(this.$refs.top_menu_btn))
            ) {
                this.menu = false
            }
        }

    },

    computed: {
        ...mapGetters(['colorTheme', 'showUserName']),
    },
};
</script>