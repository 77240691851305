<template>
    <template v-if="keepAlive">
        <div v-show="activeTabName===name">
            <slot></slot>
        </div>
    </template>
    <template v-else>
        <div v-if="activeTabName===name">
            <slot></slot>
        </div>
    </template>
</template>

<script>
export default {
    name: "LdiTab",
    props: {
        name: {
            type: String,
            required: true
        },
        activeTabName: {
            type: String,
        },
        keepAlive: Boolean
    },
}
</script>