<template>
    <ServersLdiPage :breadcrumbs='breadcrumbs'>

        <ServerConfigurator v-if='source' :source='source' @onConfigured='showConfirmPopup' />

        <LdiPrompt
            :is-show='isShowConfirmPopup'
            :messages='["Are you sure?", "Please check all data TWICE!"]'
            @onCancel='closeConfirmPopup' @onConfirm='update'
        />

    </ServersLdiPage>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ServersLdiPage from '@/Pages/ServersLdiPage'
import ServerConfigurator from '@/Components/Servers/Component/ServerConfigurator'
import LdiPrompt from '@/Organisms/Popup/LdiPrompt'

const { mapActions } = createNamespacedHelpers('servers')

export default {
    components: { LdiPrompt, ServerConfigurator, ServersLdiPage },
    data: () => ({
        breadcrumbs: {},
        source: null,

        isShowConfirmPopup: false,
        configuredObject: null
    }),

    methods: {
        ...mapActions(['loadServer', 'updateServer']),

        showConfirmPopup(configuredObject) {
            this.isShowConfirmPopup = true
            this.configuredObject = configuredObject
        },

        closeConfirmPopup() {
            this.isShowConfirmPopup = false
            this.configuredObject = null
        },

        update() {
            this.updateServer({ id: this.$route.params.id, ...this.configuredObject }).finally(() => {
                this.closeConfirmPopup()
                this.$router.go(-1)
            })
        }
    },

    mounted() {
        this.loadServer(this.$route.params.id).then(loaded => {
                this.source = loaded
                this.breadcrumbs.text = 'Edit server: ' + loaded.name
            }
        )
    }
}
</script>