import { deleteRequest, getRequest, postRequest, putRequest } from '../octopusAnalyticRequests'

const uri = 'servers'

export const create = ({ name, url, token, region }) =>
    postRequest(uri, { name, url, token, region })

export const read = id => getRequest(uri + `/${id}`)

export const update = ({ id, name, url, token, region }) =>
    putRequest(uri + `/${id}`, { name, url, token, region })

export const remove = id => deleteRequest(uri + `/${id}`)

export const all = ({ page, orderBy, orderDirection }) =>
    getRequest(uri, { page, orderBy, orderDirection })
