<template>
    <div class='dashboard'>
        <LdiBreadcrumbs :crumbs='crumbs' :icon='breadcrumbsIcon' :is-loading='isLoading' />
        <div :class='getClasses' class='block block--full'>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import LdiBreadcrumbs from '@/Molecules/Breadcrumbs/LdiBreadcrumbs.vue'

export default {
    components: { LdiBreadcrumbs },
    props: {
        breadcrumbs: Array | Object | null,
        breadcrumbsIcon: {
            type: String,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        },
        pageTitle: {
            type: String,
            required: true
        },
        pageRouteName: {
            type: String
        },
        blockOverflowAuto: Boolean
    },
    methods: {
        createCrumbObject(to, text) {
            return { to, text }
        }
    },
    computed: {
        crumbs() {
            if (this.breadcrumbs) {
                return Array.isArray(this.breadcrumbs)
                    ? [this.rootPageCrumb, ...this.breadcrumbs]
                    : [this.rootPageCrumb, this.breadcrumbs]
            } else {
                return [this.rootPageCrumb]
            }
        },
        rootPageCrumb() {
            return this.createCrumbObject(this.pageRouteName, this.pageTitle)
        },
        getClasses() {
            return {
                'block--overflowAuto': this.blockOverflowAuto,
                'scroll-thin': this.blockOverflowAuto
            }
        }
    }
}
</script>