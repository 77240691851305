<template>
    <label class="toggle-box" style="width: fit-content" @click="emitOnToggle">
        <span :class="{'toggle-activeText': !isActive }">
            {{ text ? textOff : '' }}
        </span>
        <input v-model="isActive" class="toggle" type="checkbox">
        <span :class="{ 'toggle-activeText': isActive }">
            {{ text ? textOn : '' }}
        </span>
    </label>
</template>
  
<script>
export default {
    name: "LdiToggleButton",
    props: {
        text: Boolean,
        modelValue: Boolean,
        textOn: {
          type: String,
          default: "On"
        },
        textOff: {
          type: String,
          default: "Off"
        }
    },
    computed: {
        isActive: {
          get() {
            return this.modelValue;
          },
          set(value) {
            this.$emit('update:modelValue', value);
          }
        }
    },
    methods: {
        emitOnToggle() {
            this.isActive = !this.isActive;
        }
    }
  }
</script>

<style lang="scss" scoped>
@import "../assets/scss/animation/from-left-to-right";

.toggle{
    &-box {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: .5rem;
        
        &:focus {
            border: 2px solid red;
        }
    }
    &-activeText{
        transition-delay: 0.2s;
        color: var(--input-color);
    }
}


label > .toggle {
    width: 2rem;
    height: .5rem;
    display: flex;
    align-items: center;

    $transition-time: .2s;
    $transition-delay: .05s;

    &:checked {
        &:after {
            transition: all $transition-time ease-in-out $transition-delay;
            transform: translate(100%, -50%);
            background: var(--input-color);
        }

        &:before {
            transition: background $transition-time ease-in-out $transition-delay;
            background: var(--dark-cyan);
        }
    }

    &::before {
        display: flex;
        align-items: center;
        content: '';
        width: 2rem;
        height: .75rem;
        border-radius: 1em;
        background: var(--info-color);
        position: relative;
        cursor: pointer;
        transition: background $transition-time ease-out $transition-delay;
    }

    &::after {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        content: '';
        width: 1rem;
        height: 1rem;
        background: var(--light-gray);
        border-radius: 50%;
        animation-duration: 2s;
        animation-name: toRight;
        cursor: pointer;
        transition: all $transition-time ease-out $transition-delay;
    }

    &:focus {
        border: 2px solid red;
    }
}
</style>