import LdiIcon from "@/Atoms/LdiIcon.vue";
import LdiButton from "@/Atoms/LdiButton.vue";
import LdiSpinner from "@/Atoms/LdiSpinner.vue";
import LdiTab from "@/Atoms/Tab/LdiTab.vue";
import LdiTabs from "@/Atoms/Tab/LdiTabs.vue";
import LdiBreadcrumbs from "@/Molecules/Breadcrumbs/LdiBreadcrumbs.vue";
import LdiPage from "@/Templates/LdiPage.vue";
import vSelect from 'vue-select';


export default function (app) {
    app.component('LdiIcon', LdiIcon)
    app.component('LdiButton', LdiButton)
    app.component('LdiSpinner', LdiSpinner)
    app.component('LdiTab', LdiTab)
    app.component('LdiTabs', LdiTabs)

    app.component('LdiBreadcrumbs', LdiBreadcrumbs)
    app.component('LdiPage', LdiPage)
    app.component('v-select', vSelect)
}