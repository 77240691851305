<template>
    <edit-role-configurator
        :loadUsersListRoles="loadUsersListRoles"
        :loadUsersListPermissions="loadUsersListPermissions"
        pageName="user"/>
</template>

<script>
import {mapActions} from "vuex";
import EditRoleConfigurator from './Components/EditRoleConfigurator.vue';

export default {
    components: { EditRoleConfigurator },

    methods: {
        ...mapActions([ 'loadUsersListPermissions', 'loadUsersListRoles' ]),
    },
}
</script>