<template>
    <RolesLdiPage>
        <LdiTabs :tab-names="$options.TABS" 
            :initialActiveTabName="this.$route.query.startTabName">
            <template v-slot="fromSlot">
                <LdiTab v-bind="fromSlot" :name="$options.TABS[0]">
                    <roles-configurator :loadUsersListRoles="loadUsersListRoles" pageName="user"/>
                </LdiTab>
                <LdiTab v-bind="fromSlot" :name="$options.TABS[1]">
                    <roles-configurator :loadUsersListRoles="loadBuyersListRoles" pageName="buyer"/>
                </LdiTab>
            </template>
        </LdiTabs>
    </RolesLdiPage>
</template>

<script>
import {mapActions} from "vuex";
import RolesLdiPage from "../../Pages/RolesLdiPage.vue";

import LdiTabs from '@/Atoms/Tab/LdiTabs.vue'
import LdiTab from '@/Atoms/Tab/LdiTab.vue'
import RolesConfigurator from './Components/RolesConfigurator.vue';

export default {
    TABS: ['Users roles', 'Buyers roles'],

    components: {RolesLdiPage, LdiTabs, LdiTab, RolesConfigurator },

    methods: {
        ...mapActions(['loadUsersListRoles', 'loadBuyersListRoles']),
        
        edit(id) {
            this.$router.push({name: 'roles.edit', params: {id}})
        }
    }
}
</script>

<style scoped>
tr {
    cursor: pointer;
}
</style>