<template>
    <div :class="getClasses">
        <template v-if="action==='home'">
            <HomeIcon :height="getWidth" :width="getWidth"/>
        </template>
        <template v-else-if="action==='google'">
            <GoogleIcon :height="getWidth" :width="getWidth"/>
        </template>
        <Icon v-else :icon="getIcon" :width="getWidth"/>
    </div>
</template>

<script>
import { Icon } from '@iconify/vue'
import HomeIcon from './Icons/HomeIcon.vue'
import GoogleIcon from '@/Atoms/Icons/GoogleIcon.vue'

export default {
    name: "LdiIcon",
    components: {
        GoogleIcon,
        HomeIcon,
        Icon
    },
    props: {
        disabled: Boolean,
        action: {
            type: String,
            default: 'add',
            validator(action) {
                return ['add', 'save', 'submit', 'edit', 'show', 'remove', 'close', 'clear', 'download', 'back',
                    'user', 'fingerprint', 'partnership', 'report', 'compare', 'conversion', 'antenna', 'active',
                    'minus', 'cloud', 'home', 'group', 'share', 'filter', 'dollar', 'triangle', 'triangle-down',
                    'bar-chart', 'check', 'path', 'template', 'request', 'google-sheet', 'clipboard-copy', 'restart',
                    'google', 'direction', 'apps', 'question', 'info', 'poker', 'slot', 'casino', 'fantasy', 'exclamation',
                    'servers', 'ssl', 'expire', 'return',
                    
                    'link', 'bell', 'info',

                    'AU', 'AT', 'AZ', 'AX', 'AL', 'DZ', 'AS', 'VI', 'AI', 'AO', 'AD', 'AQ', 'AG', 'AR', 'AW', 'AF',
                    'BS', 'BD', 'BB', 'BH', 'BZ', 'BE', 'BJ', 'BM', 'BY', 'BG', 'BO', 'BA', 'BW', 'BR', 'IO', 'VG',
                    'BN', 'BF', 'BI', 'BT', 'VU', 'VA', 'GB', 'VE', 'VN', 'AM', 'WF', 'GA', 'HT', 'GY', 'GM', 'GH',
                    'GP', 'GT', 'GN', 'GW', 'GG', 'HN', 'HK', 'GD', 'GR', 'GE', 'GU', 'GI', 'GL', 'DK', 'CD', 'JE',
                    'DJ', 'DM', 'DO', 'UM', 'EC', 'GQ', 'ER', 'EE', 'ET', 'EG', 'YE', 'ZM', 'EH', 'ZW', 'IL', 'IN',
                    'ID', 'IQ', 'IR', 'IE', 'IS', 'ES', 'IT', 'JO', 'CV', 'KZ', 'KY', 'KH', 'CM', 'CA', 'QA', 'KE',
                    'KG', 'CN', 'CY', 'KI', 'CC', 'CO', 'KM', 'CG', 'CR', 'CI', 'CU', 'KW', 'CW', 'LA', 'LV', 'LS',
                    'LT', 'LR', 'LB', 'LY', 'LI', 'LU', 'MU', 'MR', 'MG', 'YT', 'MO', 'MW', 'MY', 'ML', 'MV', 'MT',
                    'MA', 'MQ', 'MH', 'MX', 'MZ', 'MD', 'MC', 'MN', 'MS', 'MM', 'NA', 'NR', 'NP', 'NE', 'NG', 'NL',
                    'AN', 'BQ', 'NI', 'DE', 'NU', 'NZ', 'NC', 'NO', 'AE', 'OM', 'BV', 'IM', 'NF', 'CX', 'SH', 'HM',
                    'CK', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'ZA', 'GS', 'KR', 'SS', 'KP', 'MK', 'MP', 'PN',
                    'PL', 'PT', 'PR', 'RE', 'RU', 'RW', 'RO', 'SV', 'WS', 'SM', 'ST', 'SA', 'SZ', 'SJ', 'SC', 'BL',
                    'SN', 'MF', 'PM', 'VC', 'KN', 'LC', 'RS', 'SY', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'US', 'SD',
                    'SR', 'TL', 'SL', 'TJ', 'TH', 'TW', 'TZ', 'TC', 'TG', 'TK', 'TO', 'TT', 'TV', 'TN', 'TR', 'TM',
                    'UG', 'HU', 'UZ', 'UA', 'UY', 'FO', 'FM', 'FJ', 'PH', 'FI', 'FK', 'FR', 'GF', 'PF', 'TF', 'HR',
                    'CF', 'TD', 'CZ', 'CL', 'ME', 'CH', 'SE', 'LK', 'JM', 'JP',
                    'ALL',
                ].includes(action)
            }
        },
        size: {
            type: String,
            default: 's',
            validator(size) {
                return ['xs', 's', 'm', 'l', 'xl'].includes(size)
            }
        },
        color: {
            type: String,
            default: null,
            validator(color) {
                return ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'default', 'black', 'white'].includes(color)
            }
        },
    },
    computed: {
        getWidth() {
            switch (this.size) {
                case 'xs' :
                    return 12
                case 's' :
                    return 16
                case 'm' :
                    return 20
                case 'l' :
                    return 24
                case 'xl' :
                    return 32

                default:
                    return 16
            }
        },
        getIcon() {
            switch (this.action) {

                case 'ALL':
                    return 'subway:world'
                case 'AU':
                    return 'flagpack:AU'.toLowerCase()
                case 'AT':
                    return 'flagpack:AT'.toLowerCase()
                case 'AZ':
                    return 'flagpack:AZ'.toLowerCase()
                case 'AX':
                    return 'flagpack:AX'.toLowerCase()
                case 'AL':
                    return 'flagpack:AL'.toLowerCase()
                case 'DZ':
                    return 'flagpack:DZ'.toLowerCase()
                case 'AS':
                    return 'flagpack:AS'.toLowerCase()
                case 'VI':
                    return 'flagpack:VI'.toLowerCase()
                case 'AI':
                    return 'flagpack:AI'.toLowerCase()
                case 'AO':
                    return 'flagpack:AO'.toLowerCase()
                case 'AD':
                    return 'flagpack:AD'.toLowerCase()
                case 'AQ':
                    return 'flagpack:AQ'.toLowerCase()
                case 'AG':
                    return 'flagpack:AG'.toLowerCase()
                case 'AR':
                    return 'flagpack:AR'.toLowerCase()
                case 'AW':
                    return 'flagpack:AW'.toLowerCase()
                case 'AF':
                    return 'flagpack:AF'.toLowerCase()
                case 'BS':
                    return 'flagpack:BS'.toLowerCase()
                case 'BD':
                    return 'flagpack:BD'.toLowerCase()
                case 'BB':
                    return 'flagpack:BB'.toLowerCase()
                case 'BH':
                    return 'flagpack:BH'.toLowerCase()
                case 'BZ':
                    return 'flagpack:BZ'.toLowerCase()
                case 'BE':
                    return 'flagpack:BE'.toLowerCase()
                case 'BJ':
                    return 'flagpack:BJ'.toLowerCase()
                case 'BM':
                    return 'flagpack:BM'.toLowerCase()
                case 'BY':
                    return 'flagpack:BY'.toLowerCase()
                case 'BG':
                    return 'flagpack:BG'.toLowerCase()
                case 'BO':
                    return 'flagpack:BO'.toLowerCase()
                case 'BA':
                    return 'flagpack:BA'.toLowerCase()
                case 'BW':
                    return 'flagpack:BW'.toLowerCase()
                case 'BR':
                    return 'flagpack:BR'.toLowerCase()
                case 'IO':
                    return 'flagpack:IO'.toLowerCase()
                case 'VG':
                    return 'flagpack:VG'.toLowerCase()
                case 'BN':
                    return 'flagpack:BN'.toLowerCase()
                case 'BF':
                    return 'flagpack:BF'.toLowerCase()
                case 'BI':
                    return 'flagpack:BI'.toLowerCase()
                case 'BT':
                    return 'flagpack:BT'.toLowerCase()
                case 'VU':
                    return 'flagpack:VU'.toLowerCase()
                case 'VA':
                    return 'flagpack:VA'.toLowerCase()
                case 'GB':
                    return 'flagpack:GB'.toLowerCase()
                case 'VE':
                    return 'flagpack:VE'.toLowerCase()
                case 'VN':
                    return 'flagpack:VN'.toLowerCase()
                case 'AM':
                    return 'flagpack:AM'.toLowerCase()
                case 'WF':
                    return 'flagpack:WF'.toLowerCase()
                case 'GA':
                    return 'flagpack:GA'.toLowerCase()
                case 'HT':
                    return 'flagpack:HT'.toLowerCase()
                case 'GY':
                    return 'flagpack:GY'.toLowerCase()
                case 'GM':
                    return 'flagpack:GM'.toLowerCase()
                case 'GH':
                    return 'flagpack:GH'.toLowerCase()
                case 'GP':
                    return 'flagpack:GP'.toLowerCase()
                case 'GT':
                    return 'flagpack:GT'.toLowerCase()
                case 'GN':
                    return 'flagpack:GN'.toLowerCase()
                case 'GW':
                    return 'flagpack:GW'.toLowerCase()
                case 'GG':
                    return 'flagpack:GG'.toLowerCase()
                case 'HN':
                    return 'flagpack:HN'.toLowerCase()
                case 'HK':
                    return 'flagpack:HK'.toLowerCase()
                case 'GD':
                    return 'flagpack:GD'.toLowerCase()
                case 'GR':
                    return 'flagpack:GR'.toLowerCase()
                case 'GE':
                    return 'flagpack:GE'.toLowerCase()
                case 'GU':
                    return 'flagpack:GU'.toLowerCase()
                case 'GI':
                    return 'flagpack:GI'.toLowerCase()
                case 'GL':
                    return 'flagpack:GL'.toLowerCase()
                case 'DK':
                    return 'flagpack:DK'.toLowerCase()
                case 'CD':
                    return 'flagpack:CD'.toLowerCase()
                case 'JE':
                    return 'flagpack:JE'.toLowerCase()
                case 'DJ':
                    return 'flagpack:DJ'.toLowerCase()
                case 'DM':
                    return 'flagpack:DM'.toLowerCase()
                case 'DO':
                    return 'flagpack:DO'.toLowerCase()
                case 'UM':
                    return 'flagpack:UM'.toLowerCase()
                case 'EC':
                    return 'flagpack:EC'.toLowerCase()
                case 'GQ':
                    return 'flagpack:GQ'.toLowerCase()
                case 'ER':
                    return 'flagpack:ER'.toLowerCase()
                case 'EE':
                    return 'flagpack:EE'.toLowerCase()
                case 'ET':
                    return 'flagpack:ET'.toLowerCase()
                case 'EG':
                    return 'flagpack:EG'.toLowerCase()
                case 'YE':
                    return 'flagpack:YE'.toLowerCase()
                case 'ZM':
                    return 'flagpack:ZM'.toLowerCase()
                case 'EH':
                    return 'flagpack:EH'.toLowerCase()
                case 'ZW':
                    return 'flagpack:ZW'.toLowerCase()
                case 'IL':
                    return 'flagpack:IL'.toLowerCase()
                case 'IN':
                    return 'flagpack:IN'.toLowerCase()
                case 'ID':
                    return 'flagpack:ID'.toLowerCase()
                case 'IQ':
                    return 'flagpack:IQ'.toLowerCase()
                case 'IR':
                    return 'flagpack:IR'.toLowerCase()
                case 'IE':
                    return 'flagpack:IE'.toLowerCase()
                case 'IS':
                    return 'flagpack:IS'.toLowerCase()
                case 'ES':
                    return 'flagpack:ES'.toLowerCase()
                case 'IT':
                    return 'flagpack:IT'.toLowerCase()
                case 'JO':
                    return 'flagpack:JO'.toLowerCase()
                case 'CV':
                    return 'flagpack:CV'.toLowerCase()
                case 'KZ':
                    return 'flagpack:KZ'.toLowerCase()
                case 'KY':
                    return 'flagpack:KY'.toLowerCase()
                case 'KH':
                    return 'flagpack:KH'.toLowerCase()
                case 'CM':
                    return 'flagpack:CM'.toLowerCase()
                case 'CA':
                    return 'flagpack:CA'.toLowerCase()
                case 'QA':
                    return 'flagpack:QA'.toLowerCase()
                case 'KE':
                    return 'flagpack:KE'.toLowerCase()
                case 'KG':
                    return 'flagpack:KG'.toLowerCase()
                case 'CN':
                    return 'flagpack:CN'.toLowerCase()
                case 'CY':
                    return 'flagpack:CY'.toLowerCase()
                case 'KI':
                    return 'flagpack:KI'.toLowerCase()
                case 'CC':
                    return 'flagpack:CC'.toLowerCase()
                case 'CO':
                    return 'flagpack:CO'.toLowerCase()
                case 'KM':
                    return 'flagpack:KM'.toLowerCase()
                case 'CG':
                    return 'flagpack:CG'.toLowerCase()
                case 'CR':
                    return 'flagpack:CR'.toLowerCase()
                case 'CI':
                    return 'flagpack:CI'.toLowerCase()
                case 'CU':
                    return 'flagpack:CU'.toLowerCase()
                case 'KW':
                    return 'flagpack:KW'.toLowerCase()
                case 'CW':
                    return 'flagpack:CW'.toLowerCase()
                case 'LA':
                    return 'flagpack:LA'.toLowerCase()
                case 'LV':
                    return 'flagpack:LV'.toLowerCase()
                case 'LS':
                    return 'flagpack:LS'.toLowerCase()
                case 'LT':
                    return 'flagpack:LT'.toLowerCase()
                case 'LR':
                    return 'flagpack:LR'.toLowerCase()
                case 'LB':
                    return 'flagpack:LB'.toLowerCase()
                case 'LY':
                    return 'flagpack:LY'.toLowerCase()
                case 'LI':
                    return 'flagpack:LI'.toLowerCase()
                case 'LU':
                    return 'flagpack:LU'.toLowerCase()
                case 'MU':
                    return 'flagpack:MU'.toLowerCase()
                case 'MR':
                    return 'flagpack:MR'.toLowerCase()
                case 'MG':
                    return 'flagpack:MG'.toLowerCase()
                case 'YT':
                    return 'flagpack:YT'.toLowerCase()
                case 'MO':
                    return 'flagpack:MO'.toLowerCase()
                case 'MW':
                    return 'flagpack:MW'.toLowerCase()
                case 'MY':
                    return 'flagpack:MY'.toLowerCase()
                case 'ML':
                    return 'flagpack:ML'.toLowerCase()
                case 'MV':
                    return 'flagpack:MV'.toLowerCase()
                case 'MT':
                    return 'flagpack:MT'.toLowerCase()
                case 'MA':
                    return 'flagpack:MA'.toLowerCase()
                case 'MQ':
                    return 'flagpack:MQ'.toLowerCase()
                case 'MH':
                    return 'flagpack:MH'.toLowerCase()
                case 'MX':
                    return 'flagpack:MX'.toLowerCase()
                case 'MZ':
                    return 'flagpack:MZ'.toLowerCase()
                case 'MD':
                    return 'flagpack:MD'.toLowerCase()
                case 'MC':
                    return 'flagpack:MC'.toLowerCase()
                case 'MN':
                    return 'flagpack:MN'.toLowerCase()
                case 'MS':
                    return 'flagpack:MS'.toLowerCase()
                case 'MM':
                    return 'flagpack:MM'.toLowerCase()
                case 'NA':
                    return 'flagpack:NA'.toLowerCase()
                case 'NR':
                    return 'flagpack:NR'.toLowerCase()
                case 'NP':
                    return 'flagpack:NP'.toLowerCase()
                case 'NE':
                    return 'flagpack:NE'.toLowerCase()
                case 'NG':
                    return 'flagpack:NG'.toLowerCase()
                case 'NL':
                    return 'flagpack:NL'.toLowerCase()
                case 'AN':
                    return 'circle-flags:an'
                case 'BQ':
                    return 'flag:bq-4x3'
                case 'NI':
                    return 'flagpack:NI'.toLowerCase()
                case 'DE':
                    return 'flagpack:DE'.toLowerCase()
                case 'NU':
                    return 'flagpack:NU'.toLowerCase()
                case 'NZ':
                    return 'flagpack:NZ'.toLowerCase()
                case 'NC':
                    return 'flagpack:NC'.toLowerCase()
                case 'NO':
                    return 'flagpack:NO'.toLowerCase()
                case 'AE':
                    return 'flagpack:AE'.toLowerCase()
                case 'OM':
                    return 'flagpack:OM'.toLowerCase()
                case 'BV':
                    return 'flagpack:BV'.toLowerCase()
                case 'IM':
                    return 'flagpack:IM'.toLowerCase()
                case 'NF':
                    return 'flagpack:NF'.toLowerCase()
                case 'CX':
                    return 'flagpack:CX'.toLowerCase()
                case 'SH':
                    return 'flagpack:SH'.toLowerCase()
                case 'HM':
                    return 'flagpack:HM'.toLowerCase()
                case 'CK':
                    return 'flagpack:CK'.toLowerCase()
                case 'PK':
                    return 'flagpack:PK'.toLowerCase()
                case 'PW':
                    return 'flagpack:PW'.toLowerCase()
                case 'PS':
                    return 'flagpack:PS'.toLowerCase()
                case 'PA':
                    return 'flagpack:PA'.toLowerCase()
                case 'PG':
                    return 'flagpack:PG'.toLowerCase()
                case 'PY':
                    return 'flagpack:PY'.toLowerCase()
                case 'PE':
                    return 'flagpack:PE'.toLowerCase()
                case 'ZA':
                    return 'flagpack:ZA'.toLowerCase()
                case 'GS':
                    return 'flagpack:GS'.toLowerCase()
                case 'KR':
                    return 'flagpack:KR'.toLowerCase()
                case 'SS':
                    return 'flagpack:SS'.toLowerCase()
                case 'KP':
                    return 'flagpack:KP'.toLowerCase()
                case 'MK':
                    return 'flagpack:MK'.toLowerCase()
                case 'MP':
                    return 'flagpack:MP'.toLowerCase()
                case 'PN':
                    return 'flagpack:PN'.toLowerCase()
                case 'PL':
                    return 'flagpack:PL'.toLowerCase()
                case 'PT':
                    return 'flagpack:PT'.toLowerCase()
                case 'PR':
                    return 'flagpack:PR'.toLowerCase()
                case 'RE':
                    return 'flagpack:re'
                case 'RU':
                    return 'tabler:error-404'
                case 'RW':
                    return 'flagpack:RW'.toLowerCase()
                case 'RO':
                    return 'flagpack:RO'.toLowerCase()
                case 'SV':
                    return 'flagpack:SV'.toLowerCase()
                case 'WS':
                    return 'flagpack:WS'.toLowerCase()
                case 'SM':
                    return 'flagpack:SM'.toLowerCase()
                case 'ST':
                    return 'flagpack:ST'.toLowerCase()
                case 'SA':
                    return 'flagpack:SA'.toLowerCase()
                case 'SZ':
                    return 'flagpack:SZ'.toLowerCase()
                case 'SJ':
                    return 'flagpack:SJ'.toLowerCase()
                case 'SC':
                    return 'flagpack:SC'.toLowerCase()
                case 'BL':
                    return 'flagpack:BL'.toLowerCase()
                case 'SN':
                    return 'flagpack:SN'.toLowerCase()
                case 'MF':
                    return 'flagpack:MF'.toLowerCase()
                case 'PM':
                    return 'flagpack:PM'.toLowerCase()
                case 'VC':
                    return 'flagpack:VC'.toLowerCase()
                case 'KN':
                    return 'flagpack:KN'.toLowerCase()
                case 'LC':
                    return 'flagpack:LC'.toLowerCase()
                case 'RS':
                    return 'flagpack:RS'.toLowerCase()
                case 'SY':
                    return 'flagpack:SY'.toLowerCase()
                case 'SG':
                    return 'flagpack:SG'.toLowerCase()
                case 'SX':
                    return 'flagpack:SX'.toLowerCase()
                case 'SK':
                    return 'flagpack:SK'.toLowerCase()
                case 'SI':
                    return 'flagpack:SI'.toLowerCase()
                case 'SB':
                    return 'flagpack:SB'.toLowerCase()
                case 'SO':
                    return 'flagpack:SO'.toLowerCase()
                case 'US':
                    return 'flagpack:US'.toLowerCase()
                case 'SD':
                    return 'flagpack:SD'.toLowerCase()
                case 'SR':
                    return 'flagpack:SR'.toLowerCase()
                case 'TL':
                    return 'flagpack:TL'.toLowerCase()
                case 'SL':
                    return 'flagpack:SL'.toLowerCase()
                case 'TJ':
                    return 'flagpack:TJ'.toLowerCase()
                case 'TH':
                    return 'flagpack:TH'.toLowerCase()
                case 'TW':
                    return 'flagpack:TW'.toLowerCase()
                case 'TZ':
                    return 'flagpack:TZ'.toLowerCase()
                case 'TC':
                    return 'flagpack:TC'.toLowerCase()
                case 'TG':
                    return 'flagpack:TG'.toLowerCase()
                case 'TK':
                    return 'flagpack:TK'.toLowerCase()
                case 'TO':
                    return 'flagpack:TO'.toLowerCase()
                case 'TT':
                    return 'flagpack:TT'.toLowerCase()
                case 'TV':
                    return 'flagpack:TV'.toLowerCase()
                case 'TN':
                    return 'flagpack:TN'.toLowerCase()
                case 'TR':
                    return 'flagpack:TR'.toLowerCase()
                case 'TM':
                    return 'flagpack:TM'.toLowerCase()
                case 'UG':
                    return 'flagpack:UG'.toLowerCase()
                case 'HU':
                    return 'flagpack:HU'.toLowerCase()
                case 'UZ':
                    return 'flagpack:UZ'.toLowerCase()
                case 'UA':
                    return 'flagpack:UA'.toLowerCase()
                case 'UY':
                    return 'flagpack:UY'.toLowerCase()
                case 'FO':
                    return 'flagpack:FO'.toLowerCase()
                case 'FM':
                    return 'flagpack:FM'.toLowerCase()
                case 'FJ':
                    return 'flagpack:FJ'.toLowerCase()
                case 'PH':
                    return 'flagpack:PH'.toLowerCase()
                case 'FI':
                    return 'flagpack:FI'.toLowerCase()
                case 'FK':
                    return 'flagpack:FK'.toLowerCase()
                case 'FR':
                    return 'flagpack:FR'.toLowerCase()
                case 'GF':
                    return 'flagpack:GF'.toLowerCase()
                case 'PF':
                    return 'flagpack:PF'.toLowerCase()
                case 'TF':
                    return 'flagpack:TF'.toLowerCase()
                case 'HR':
                    return 'flagpack:HR'.toLowerCase()
                case 'CF':
                    return 'flagpack:CF'.toLowerCase()
                case 'TD':
                    return 'flagpack:TD'.toLowerCase()
                case 'CZ':
                    return 'flagpack:CZ'.toLowerCase()
                case 'CL':
                    return 'flagpack:CL'.toLowerCase()
                case 'ME':
                    return 'flagpack:ME'.toLowerCase()
                case 'CH':
                    return 'flagpack:CH'.toLowerCase()
                case 'SE':
                    return 'flagpack:SE'.toLowerCase()
                case 'LK':
                    return 'flagpack:LK'.toLowerCase()
                case 'JM':
                    return 'flagpack:JM'.toLowerCase()
                case 'JP':
                    return 'flagpack:JP'.toLowerCase()

                case 'add' :
                    return 'carbon:add'
                case 'save' :
                    return 'carbon:save'
                case 'submit' :
                    return 'carbon:send-alt'
                case 'edit' :
                    return 'carbon:edit'
                case 'show' :
                    return 'carbon:view'
                case 'remove' :
                    return 'carbon:trash-can'
                case 'close' :
                    return 'carbon:close'
                case 'download' :
                    return 'carbon:download'
                case 'back':
                    return 'akar-icons:arrow-left'
                case 'filter' :
                    return 'fa6-solid:filter'
                case 'clear' :
                    // return 'carbon:filter-remove'
                    return 'fa6-solid:filter-circle-xmark'
                case 'share' :
                    return 'ic:baseline-share'
                case 'return' :
                    return 'icon-park-outline:return'

                //pages icons
                case 'user':
                    return 'fa:user-o'
                case 'fingerprint':
                    return 'ic:round-fingerprint'
                case 'partnership':
                    return 'carbon:partnership'
                case 'report':
                    return 'bxs:report'
                case'compare' :
                    return 'carbon:compare'
                case'conversion' :
                    return 'icon-park-outline:file-conversion'
                case'antenna' :
                    return 'tabler:antenna-bars-5'
                case'active' :
                    return 'bi:check-lg'
                case'minus' :
                    return 'akar-icons:minus'
                case'cloud' :
                    return 'bi:cloud'
                case'group' :
                    return 'clarity:blocks-group-line'
                case'sort' :
                    return 'bx:sort'
                case'dollar' :
                    return 'ph:currency-dollar-bold'
                case'triangle' :
                    return 'fluent:triangle-12-filled'
                case'triangle-down' :
                    return 'fluent:triangle-down-12-filled'
                case'bar-chart':
                    return 'ic:outline-ssid-chart'
                case'check' :
                    return 'bi:check-lg'
                case'template' :
                    return 'tabler:template'
                case'path' :
                    return 'ph:path-bold'

                case'google-sheet' :
                    return 'simple-icons:googlesheets'
                case'request' :
                    return 'teenyicons:send-right-solid'
                case'clipboard-copy' :
                    return 'lucide:clipboard-copy'
                case'restart' :
                    return 'carbon:restart'
                case'direction' :
                    return 'uim:direction'
                case'apps' :
                    return 'ri:apps-line'

                case'question' :
                    return 'material-symbols:question-mark'
                case 'info':
                    return 'charm:info'
                case'casino' :
                    return 'cil:casino'
                case'slot' :
                    return 'mdi:slot-machine-outline'
                case'poker' :
                    return 'mdi:poker-chip'
                case'fantasy' :
                    return 'mdi:fantasy'
                case'exclamation' :
                    return 'bi:exclamation-triangle-fill'
                case'servers' :
                    return 'uil:server'
                case 'ssl':
                    return 'streamline:interface-pad-lock-shield-combination-combo-lock-locked-padlock-secure-security-shield-square'
                case 'expire':
                    return 'iconoir:time-zone'

                case 'bell':
                    return 'ph:bell-bold'
                case 'link':
                    return 'ph:link-bold'
                case 'info':
                    return 'ph:info-bold'
            }
        },
        getColor() {
            if (this.color) {
                return this.color
            }

            if (['add', 'download', 'show', 'share', 'triangle', 'triangle-down'].includes(this.action)) {
                return 'primary'
            } else if (['edit', 'close'].includes(this.action)) {
                return 'warning'
            } else if (['save', 'submit', 'return'].includes(this.action)) {
                return 'success'
            } else if (['remove'].includes(this.action)) {
                return 'danger'
            } else if (['clear'].includes(this.action)) {
                return 'secondary'
            }

            return this.color
        },
        getClasses() {
            return {
                icon: true,
                disabled: this.disabled,
                [this.getColor]: true
            }
        }
    }
}
</script>

<style scoped>
.icon {
    cursor: pointer;
    width: fit-content
}

.disabled {
    cursor: not-allowed;
}
</style>