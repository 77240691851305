<template>
    <LdiPopup :isOpen='isShow' @onClose='emitOnCancel' style="background: rgba(0, 0, 0, 0.4);">
        <div class='column column--center'>
            <div v-for='text in messages'>{{ text }}</div>
            <div class='row row--jc-space-between' style='width: 100%; margin-top: 1rem;'>
                <LdiButton color='success' @click='emitOnConfirm' :disabled="isDisabled">{{ agreeBtn }}</LdiButton>
                <LdiButton color='warning' @click='emitOnCancel'>Cancel</LdiButton>
            </div>
        </div>
    </LdiPopup>
</template>

<script>
import LdiPopup from '@/Molecules/Popup/LdiPopup.vue'
import LdiButton from '@/Atoms/LdiButton'

export default {
    name: "LdiPrompt",
    emits: ['onCancel', 'onConfirm'],
    components: { LdiButton, LdiPopup },

    props: {
        messages: Array,
        isShow: {
            type: Boolean,
            default: true
        },
        agreeBtn: {
            type: String,
            default: 'OK'
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        emitOnCancel() {
            this.$emit('onCancel')
        },
        emitOnConfirm() {
            this.$emit('onConfirm')
        }
    }

}
</script>