import requests from "../requests";
import {URL_OCTOPUS_ANALYTIC_V1} from "../config";

const _requests = requests().setApiUrl(URL_OCTOPUS_ANALYTIC_V1)

export const postRequest = _requests.postRequest.bind(_requests)
export const getRequest = _requests.getRequest.bind(_requests)
export const getBuyerRequest = _requests.getBuyerRequest.bind(_requests)
export const putRequest = _requests.putRequest.bind(_requests)
export const deleteRequest = _requests.deleteRequest.bind(_requests)
export const postRequestMultipart = _requests.postRequestMultipart.bind(_requests)
export const putRequestMultipart = _requests.putRequestMultipart.bind(_requests)
export const postRequestDownload = _requests.postRequestDownload.bind(_requests)
export const getRequestDownload = _requests.getRequestDownload.bind(_requests)
export const downloadFile = _requests.downloadFile.bind(_requests)