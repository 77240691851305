<template>
    <AppsLdiPage :breadcrumbs="{text: 'Create App'}">

        <AppConfigurator @onConfigured='showConfirmPopup' isCreateMode/>

        <LdiPrompt
            :is-show='isShowConfirmPopup'
            :messages='["Are you sure?", "Please check all data TWICE!"]'
            @onCancel='closeConfirmPopup' @onConfirm='create'
        />

    </AppsLdiPage>
</template>

<script>
import AppsLdiPage from '@/Pages/AppsLdiPage'
import AppConfigurator from '@/Components/Apps/Component/AppConfigurator'
import { createNamespacedHelpers } from 'vuex'
import LdiPrompt from '@/Organisms/Popup/LdiPrompt'

const { mapActions } = createNamespacedHelpers('apps')

export default {
    components: { LdiPrompt, AppConfigurator, AppsLdiPage },

    data: ()=>({
        isShowConfirmPopup: false,
        configuredObject: null
    }),

    methods: {
        ...mapActions(['createApp']),

        create() {
            this.createApp(this.configuredObject).finally(() => {
                this.closeConfirmPopup()
                this.$router.go(-1)
            })
        },

        showConfirmPopup(configuredObject) {
            this.isShowConfirmPopup = true
            this.configuredObject = configuredObject
        },

        closeConfirmPopup() {
            this.isShowConfirmPopup = false
            this.configuredObject = null
        },
    }
}
</script>