import Api from '../../Packages/api'

export default {
    namespaced: true,
    state: {
        paginated: {},
        domains: [],
        bundles: [],
        names: [],
    },

    getters: {
        getApps: state => state.paginated,
        getDomains: state => state.domains,
        getBundles: state => state.bundles,
        getNames: state => state.names,
    },

    mutations: {
        setApps(state, data) {
            state.paginated = data
            state.domains = data?.data?.map(app => app.domain);
            state.bundles = data?.data?.map(app => app.bundle);
            state.names = data?.data?.map(app => app.name);
        }
    },

    actions: {
        loadApps({ commit }, payload = {}) {
            Api.octopus.analytic.v1.apps.all(payload)
                .then(data => commit('setApps', data))
        },

        loadApp({ commit }, id) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.apps.read(id)
                    .then(resolve)
                    .catch(reject)
            })
        },

        createApp({ commit, state }, data) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.apps.create(data)
                    .then(created => {
                        const copy = { ...state.paginated }
                        if (copy.data && Array.isArray(copy.data)) {
                            copy.data.unshift(created)
                            commit('setApps', copy)
                        }
                        resolve(created)
                    })
                    .catch(reject)
            })
        },

        updateApp({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.apps.update(payload)
                    .then(updatedApp => {
                        const copy = { ...state.paginated }
                        copy.data = copy?.data?.map(app => app.id === updatedApp.id ? updatedApp : app)
                        commit('setApps', copy)
                        resolve(updatedApp)
                    })
                    .catch(reject)
            })
        },

        removeApp({ commit, state }, id) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.apps.remove(id)
                    .then(message => {
                        const copy = { ...state.paginated }
                        copy.data = copy.data.filter(app => app.id !== id)
                        commit('setApps', copy)
                        resolve(message)
                    })
                    .catch(reject)
            })
        },
        restoreApp({ commit, state }, id) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.apps.restore(id)
                    .then(message => {
                        const copy = { ...state.paginated }
                        copy.data = copy.data.filter(app => app.id !== id)
                        commit('setApps', copy)
                        resolve(message)
                    })
                    .catch(reject)
            })
        },
        createDomainConfig({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.app.create(payload)
                    .then(resolve)
                    .catch(reject)
            })
        },
        updatePushFirebase({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.app.updateFB(payload)
                    .then(resolve)
                    .catch(reject)
            })
        },
        
    }
}