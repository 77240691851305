import Api from '../../Packages/api'

export default {
    namespaced: true,
    state: {
        paginated: { data: [] }
    },

    getters: {
        getServers: state => state.paginated
    },

    mutations: {
        setServers(state, data) {
            state.paginated = data
        }
    },

    actions: {
        loadServers({ commit }, payload = {}) {
            Api.octopus.analytic.v1.servers.all(payload)
                .then(data => commit('setServers', data))
                .catch(console.error)
        },

        loadServer({ commit }, id) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.servers.read(id)
                    .then(resolve)
                    .catch(reject)
            })
        },

        createServer({ commit, state }, data) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.servers.create(data)
                    .then(created => {
                        const copy = { ...state.paginated }
                        if (copy.data && Array.isArray(copy.data)) {
                            copy.data?.unshift(created)
                            commit('setServers', copy)
                        }
                        resolve(created)
                    })
                    .catch(reject)
            })
        },

        updateServer({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.servers.update(payload)
                    .then(updated => {
                        const copy = { ...state.paginated }
                        copy.data = copy.data.map(item => item.id === updated.id ? updated : item)
                        commit('setServers', copy)
                        resolve(updated)
                    })
                    .catch(reject)
            })
        },

        removeServer({ commit, state }, id) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.servers.remove(id)
                    .then(message => {
                        const copy = { ...state.paginated }
                        copy.data = copy.data.filter(item => item.id !== id)
                        commit('setServers', copy)
                        resolve(message)
                    })
                    .catch(reject)
            })
        }
    }
}