<template>
    <div class='scroll-thin' style='overflow-x: auto; background: inherit;'>
        <LdiPaginatedTable
            :paginated='paginated'
            :rowHeaderCellsConfiguration='getRowHeaderCellsConfiguration()'
            :initialOrderBy='getRowHeaderCellsConfiguration()[0].cellNameOrderAble'
            @onOrder='emitOnOrder' @onPaginate='emitOnPaginate' >
            <tr v-for='app in paginated.data' :key="app.id" @dblclick='emitOnEdit(app.id)' style="cursor: pointer;">
                <td>{{ app.id }}</td>
                <td style='white-space: nowrap;overflow: hidden;text-overflow: ellipsis; max-width: 250px'>{{ app.name }}</td>
                <td>{{ app.bundle }}</td>
                <td>
                    <ExpirationSslIcons :data="app" isNoBorder isNoWrap>
                        {{ app.domain }}
                    </ExpirationSslIcons>
                </td>
                <td>
                    <VerticalIcon :vertical-value='app.vertical' />
                </td>
                <td>{{ app.statistics?.all?.today?.create?.all ?? '-' }} | -</td>
                <td>{{ app.statistics?.all?.today?.receive?.all ?? '-' }} | -</td>
                <td>{{ app.statistics?.qa?.create?.all ?? '-' }} | -</td>
                <td>{{ app.statistics?.qa?.receive?.all ?? '-' }} | -</td>
                <td>{{ app.monitoring?.status ?? '-' }}</td>
                <td>{{ app.monitoring?.app_name ?? '-' }}</td>
                <td v-if="isShownColumn">{{ app.users ?? '-' }}</td>
                <td v-if="isShownColumn">{{ app.statistics?.all?.all_time?.create.all ?? '-' }}</td>
                <td v-if="isShownColumn">{{ app.statistics?.all?.all_time?.receive.all ?? '-' }}</td>
                <td v-if="isShownColumn">{{ app.statistics?.all?.all_time?.push.all ?? '-' }}</td>
                <td v-if="isShownColumn">{{ app.statistics?.all?.all_time?.conversion.all ?? '-' }}</td>
                <td>
                    <div class='row row-ai-center row--nowrap'>
                        <LdiButton action='show' icon size='s' @click='emitOnShow(app.id)' />
                        <LdiButton action='edit' icon size='s' @click='emitOnEdit(app.id)' />
                        <div v-if="isShownColumn">
                            <LdiButton v-if="app.deleted_at" action='return' icon size='s' @click='emitOnRestore(app.id)' />
                            <LdiButton v-else action='remove' icon size='s' @click='emitOnRemove(app.id)' />
                        </div>

                    </div>
                </td>
            </tr>
        </LdiPaginatedTable>
    </div>
</template>

<script>
import LdiPaginatedTable from '@/Organisms/Table/LdiPaginatedTable'
import LdiButton from '@/Atoms/LdiButton'
import VerticalIcon from '@/Components/Apps/Icon/VerticalIcon'
import LdiIcon from '@/Atoms/LdiIcon'
import ExpirationSslIcons from '../Component/ExpirationSslIcons.vue'

import {auth} from '@/Packages/utils'

export default {
    emits: ['onPaginate', 'onOrder', 'onEdit', 'onRemove', 'onRestore', 'onShow'],
    components: { LdiIcon, VerticalIcon, LdiButton, LdiPaginatedTable, ExpirationSslIcons },
    props: {
        paginated: {
            type: Object
        }
    },
    data: () => ({
        isShownColumn: true,
    }),
    mounted(){
        if (!this.userCan("manager_app_delete")) {
            this.isShownColumn = false
        }
    },

    methods: {
        userCan: auth.isUserCan,
        getRowHeaderCellsConfiguration() {
            const actions = {
                    cellName: 'Actions',
                    isOrderAble: false
                }
            const headCells = [
                {
                    cellName: '#',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyId'
                },
                {
                    cellName: 'Name',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyName',
                    tooltip: "Name of the app in OCTOPUS platform. It can be non-unique and not depend on app name online. Adds when the application is created on a platform. It can be changed if needed."
                },
                {
                    cellName: 'Bundle',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyBundle',
                    tooltip: "Unique application identificator that depends on the platform. For example package.name for PlayMarket or bundleID for Apple Store. Must be unique. Adds when the application is created on a platform. Can't be changed."
                },
                {
                    cellName: 'Domain',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyDomain',
                    tooltip: "Application control internet domain. Must be unique. Adds when the application is created on a platform Can't be changed."
                },
                {
                    cellName: 'V',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyV',
                    tooltip: "Application vertical. For example gambling, betting or dating. Adds when the application is created on a platform. It can be changed if needed."
                },
                {
                    cellName: 'T_C',
                    isOrderAble: false,
                    tooltip: "The combined amount of requests|replies create. This may represent the number of users that open this app. Includes those who did not recieve URL.",

                },
                {
                    cellName: 'T_R',
                    isOrderAble: false,
                    tooltip: "The combined amount of requests|replies receive. This may represent the number of users that open this app in terms of install and receive Campaign URL."
                },
                {
                    cellName: 'QA_C',
                    isOrderAble: false,
                    tooltip: "The combined amount of requests|replies create taged as QA."

                },
                {
                    cellName: 'QA_R',
                    isOrderAble: false,
                    tooltip: "The combined amount of requests|replies receive taged as QA."
                },
                {
                    cellName: 'Bot Name',
                    isOrderAble: false,
                },
                {
                    cellName: 'Bot status',
                    isOrderAble: false,
                },
            ]
            const hiddenCells = [
                {
                    cellName: 'U',
                    isOrderAble: false,
                    tooltip: "Number of users of the OCTOPUS platform that have access to the application (those that can create STREAM and PUSH)."
                },
                {
                    cellName: 'All_C',
                    isOrderAble: false,
                    tooltip: "The combined amount of requests|replies create. This may represent the number of users that open this app. Includes those who did not recieve URL."
                },
                {
                    cellName: 'All_R',
                    isOrderAble: false,
                    tooltip: "The combined amount of requests|replies receive. This may represent the number of users that open this app in terms of install and receive Campaign URL."
                },
                {
                    cellName: 'All_Push',
                    isOrderAble: false,
                    tooltip: "All pushes received by application users. Includes opened and not opened by user."
                },
                {
                    cellName: 'All_Conversion',
                    isOrderAble: false,
                    tooltip: "All conversions send from OCTOPUS without errors on this application."
                },
            ]
            if(this.isShownColumn){
                return [...headCells, ...hiddenCells, actions]
            } else{
                return [...headCells, actions]
            }

        },
        emitOnOrder(orderConfiguration) {
            this.$emit('onOrder', orderConfiguration)
        },
        emitOnPaginate(page) {
            this.$emit('onPaginate', page)
        },
        emitOnShow(id) {
            this.$emit('onShow', id)
        },
        emitOnEdit(id) {
            this.$emit('onEdit', id)
        },
        emitOnRemove(id) {
            this.$emit('onRemove', id)
        },
        emitOnRestore(id) {
            this.$emit('onRestore', id)
        },
    }
}
</script>