import Api from '../../Packages/api'

export default {
    namespaced: true,

    state: {
        users: [],
    },

    getters: {
        getUsers: state => state.users,
    },

    mutations: {
        setUsers(state, data) {
            state.users = data
        },
    },

    actions: {
        loadUsers({commit}) {
            Api.octopus.analytic.v1.users.all()
                .then(data => commit('setUsers', data))
        },

        loadUser({commit}, id) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.users.read(id)
                    .then(resolve)
                    .catch(reject)
            })
        },

        createUser({commit}, data) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.users.create(data)
                    .then(resolve)
                    .catch(reject)
            })
        },

        updateUser({commit}, {id, data}) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.users.update(id, data)
                    .then(resolve)
                    .catch(reject)
            })
        },

        deleteUser({commit}, id){
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.users.deleteUser(id)
                    .then(resolve)
                    .catch(reject)
            })
        },

        switchAccessUser({commit}, payload) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.users.switchAccess(payload)
                    .then(resolve)
                    .catch(reject)
            })
        },

        attachApps({commit}, payload) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.users.attachApps(payload)
                    .then(resolve)
                    .catch(reject)
            })
        },

        detachApp({commit}, payload) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.users.detachApp(payload)
                    .then(resolve)
                    .catch(reject)
            })
        },

    },
}