<template>
    <div class="notifications">
        <div class="notifications-box">
            <TransitionGroup name="fade">
                <Notification
                    v-for="notification in this.getNotifications" :key="notification.timestamp?.getMilliseconds() ?? (new Date()).getMilliseconds()"
                    v-bind="notification"
                    @onClose="removeNotification"
                />

            </TransitionGroup>

            <div class="row row--jc-end">
                <LdiButton v-if="this.getNotifications.length > 3" @click="clearAll">Clear all</LdiButton>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "Notifications",

    methods: {
        ...mapMutations(['addNotification', 'removeNotification', 'clearAll']),

    },

    computed:{
        ...mapGetters(['getNotifications']),

    }
}
</script>

<style lang="scss" scoped>
.fade {
    /* 1. Оголошення переходу */
    &-move,
    &-enter-active,
    &-leave-active {
        transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
    }

    /* 2. Оголошення входу і виходу зі стану */
    &-enter-from,
    &-leave-to {
        opacity: 0;
        transform: translateX(552px);
    }

    /* 3. Переконайтеся, що елементи, які вилучаються з потоку макету є видалені, щоб анімації були розраховані коректно. */
    &-leave-active {
        position: absolute;
    }
}


</style>