export const downloadFile = (blobPartsOfFile, fileName) => {
    const url = window.URL.createObjectURL(new Blob([blobPartsOfFile]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName)
    document.body.appendChild(link);
    link.click();
}

export const generateFileNameWithDate = (fileName, fileExtension) =>
    fileName + '_' + new Date().toISOString() + '.' + fileExtension