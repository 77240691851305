<template>
    <div class="column">
        <router-link :to="{name: `${this.pageName}.roles.create`}">
            <LdiButton icon/>
        </router-link>
        <table class="roles-table">
            <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="role in usersRoles" v-bind:key="role.id" @dblclick="edit(role.id)">
                    <td>{{ role.name }}</td>
                    <td>
                        <LdiIcon action="edit" size="s" @click="edit(role.id)"/>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LdiButton from "@/Atoms/LdiButton.vue";
import LdiIcon from "@/Atoms/LdiIcon.vue";
import RolesLdiPage from "@/Pages/RolesLdiPage.vue";

export default {
    components: { RolesLdiPage, LdiIcon, LdiButton },
    props: {
        loadUsersListRoles: Function,
        pageName: String
    },
    mounted() {
        this.loadRoles()
        this.loadUsersListRoles()
    },
    computed: {
        ...mapGetters(['getRoles', 'getListRoles']),
        usersRoles() {
            let filteredArray;
            filteredArray = this.getRoles.filter(item => {
                const labelItem = this.getListRoles.find(label => label.id === item.id);
                return labelItem !== undefined;
            });
            return filteredArray
        },
    },
    methods: {
        ...mapActions(['loadRoles']),
        edit(id) {
            this.$router.push({name: `${this.pageName}.roles.edit`, params: {id}})
        }
    }
}
</script>

<style scoped>
tr {
    cursor: pointer;
}
</style>