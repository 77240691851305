import AppsPage from '../../../Components/Apps/AppsPage.vue'
import CreateAppPage from '../../../Components/Apps/CreateAppPage.vue'
import EditAppPage from '../../../Components/Apps/EditAppPage.vue'

export const apps = [
    /**                                                            Apps                       ***************/
    {
        path: '/apps',
        name: 'apps',
        component: AppsPage,
        meta: {
            permissions: [        
                "manager_app_create", 
                "manager_app_edit", 
                "manager_app_delete",
                "manager_app_requests",
                "manager_app_statistics",
            ]
        },
    },
    {
        path: '/apps/create',
        name: 'apps.create',
        component: CreateAppPage,
        meta: {
            permissions: "manager_app_create"
        },
    },
    {
        path: '/apps/:id/edit',
        name: 'apps.edit',
        component: EditAppPage,
        meta: {
            permissions: "manager_app_edit"
        },
    },
]