import Api from '../../Packages/api'

export default {
    state: {
        roles: [],
    },

    getters: {
        getRoles: state => state.roles,
    },

    mutations: {
        setRoles(state, data) {
            state.roles = data
        },
    },

    actions: {
        loadRoles({commit}) {
            Api.octopus.analytic.v1.roles.all()
                .then(data => commit('setRoles', data))
                .catch(console.error)
        },

        loadRole({commit}, id) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.roles.read(id)
                    .then(resolve)
                    .catch(reject)
            })
        },

        createRole({commit}, data) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.roles.create(data)
                    .then(resolve)
                    .catch(reject)
            })
        },

        updateRole({commit}, data) {
            Api.octopus.analytic.v1.roles.update(data)
        }
    },
}