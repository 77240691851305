<template>
    <div class="auth-popup">
        <div class="login-container">
            <div class="login-block">
                <div class="block dark">
                    <h2>You are not authorized</h2>
                    <LdiButton @click="login" style="margin: 0 auto;">Login</LdiButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LdiButton from '@/Atoms/LdiButton.vue'

export default {
    components: { LdiButton },

    methods: {
        login() {
            this.$emit("login");
        }
    }
};
</script>

<style scoped>
.auth-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    text-align: center;
}
</style>
