import Storage from '../../storage'

export const isTokenExpires = () => Storage.auth.getToken().expiresAt < Date.now()

export const isUserHasAccess = () => Storage.auth.getUser().hasAccess

export const isUserCan = permission => {
    if (Array.isArray(permission)) {
        return permission.some(itemPermission => Storage.auth.getUser().permissions?.includes(itemPermission))
    }
    return Storage.auth.getUser().permissions?.includes(permission)
}

export const isNewUser = () => Object.keys(Storage.auth.getToken() ?? {}).length === 0

export const redirectAfterAuth = router => {
    const routeNameForRedirect = Storage.auth.getRouteLinkRedirectAfterReLogIn()
    try {
        if (!!routeNameForRedirect) {
            router.push(routeNameForRedirect)
            Storage.auth.removeRouteLinkRedirectAfterReLogIn()
        } else {
            if (isUserCan(["manager_app_create"])) {
                router.push('/apps')
            } else if (isUserCan(["manager_logs"])) {
                router.push('/logs')
            } else if(!isUserCan(["manager_app_create", "manager_logs"]) ){
                router.push('/login')
            } else {
                router.push('/')
            }
        }
    } catch (error) {
        console.error("makeRedirectAfterSuccessLogin:", error)
        router.push('/')
    }
}

export const redirectAfterAuthError = router => {
    router.push({ name: 'login' })
}