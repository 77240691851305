<template>
    <div v-if="isOpen" class="popup" @click.stop="closeClickBlur">
        <div class="popup__content" @click.stop>
            <Icon v-if="closeByClickX" class="popup__close" icon="codicon:chrome-close" @click="closePopup"/>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import {Icon} from '@iconify/vue';

export default {
    components: {
        Icon
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true
        },
        closeByEscape: {
            type: Boolean,
            default: true
        },
        closeByClickBlur: {
            type: Boolean,
            default: true
        },
        closeByClickX: {
            type: Boolean,
            default: true
        },
    },
    data: () => ({}),
    mounted() {
        document.addEventListener('keydown', this.closeKeydownEscape)
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.closeKeydownEscape)
    },
    emits: {
        onClose: null,
    },
    methods: {
        closePopup() {
            this.$emit('onClose')
        },
        closeKeydownEscape(event) {
            this.closeByEscape && this.isOpen && event.key === 'Escape' && this.closePopup()
        },
        closeClickBlur() {
            this.closeByClickBlur && this.closePopup()
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base/mixins';

.popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 100;
    margin: 0;

    &__content {
        width: fit-content;
        min-width: 90vw;
        max-width: 90vw;
        background: var(--color-background);
        padding: 2.5rem 1.5rem 1.5rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    &__close {
        width: 24px;
        height: 24px;
        position: absolute;
        top: .75rem;
        right: .75rem;
        cursor: pointer;
        color: black;
    }

    @include bp(sm) {
        &__content {
            min-width: unset;
        }
    }
}
</style>