<template>
    <div></div>
</template>

<script>
import { mapActions } from 'vuex'
import Utils from '@/Packages/utils'

export default {
    data: () => ({
        code: null
    }),

    mounted() {
        this.code = this.$route.query.code

        if (!this.code) {
            this.$router.push({ name: 'login' })
        }

        this.auth()
    },

    methods: {
        ...mapActions(['loginViaGoogle']),

        auth() {
            this.loginViaGoogle(this.code)
                .then(() => Utils.auth.redirectAfterAuth(this.$router))
                .catch(() => Utils.auth.redirectAfterAuthError(this.$router))
        }
    }
}
</script>