import { IS_NEED_PRINT_LOGS } from '../api/config'
import Storage from '../storage'

export const reLogIn = (error, store, router) => {
    if (error.request.status === 401) {
        Storage.auth.removeToken()
        Storage.auth.removeUser()
        store.dispatch('logout').then(() => router.push({ name: 'login' }))
    }
}

export const log = (error, store) => {
    if (IS_NEED_PRINT_LOGS) {
        console.log('error:', error)
    }
    if (![401, 403, 404].includes(error?.response?.status ?? null)) {
        store.commit('notifyError', error)
    }
}

export const handleAuthError = (error, store) => {
    if ([401, 403, 422, 404].includes(error.response?.status ?? null)) {
        store.commit('setAuthError', error.response.data.message)
    }
}