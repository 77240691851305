<template>
    <span aria-label="Breadcrumb" class="page-title">
        <LdiSpinner v-show="isLoading"/>
        <LdiIcon v-show="!isLoading" :action="icon"/>
        <template v-for="crumb in breadcrumbsLinks" :key="crumb.text">
            <router-link v-if="crumb.to" :to="{name:crumb.to, params:{...crumb.params}}" style="text-decoration: underline">
                {{ crumb.text }}
            </router-link>
            <span v-else >{{ crumb.text }}</span>
            >
        </template>
        <span>{{ currentCrumb }}</span>
    </span>
</template>

<script>
import LdiIcon from "../../Atoms/LdiIcon.vue";
import LdiSpinner from "../../Atoms/LdiSpinner.vue";

export default {
    name: "LdiBreadcrumbs",
    components: {LdiSpinner, LdiIcon},
    props: {
        crumbs: {
            type: Array,
        },
        icon: String,
        isLoading: Boolean
    },
    computed: {
        breadcrumbsLinks() {
            return this.crumbs.length > 1 ? this.crumbs.slice(0, -1) : []
        },
        currentCrumb() {
            return this.crumbs.length === 1 ? this.crumbs[0].text : this.crumbs[this.crumbs.length - 1].text
        }
    }
}
</script>