import ServersPage from "../../../Components/Servers/ServersPage.vue";
import CreateServerPage from "../../../Components/Servers/CreateServerPage.vue";
import EditServerPage from "../../../Components/Servers/EditServerPage.vue";

export const servers = [
    /**                                                            Servers                       ***************/
    {
        path: '/servers',
        name: 'servers',
        component: ServersPage,
        meta: {
            permissions: "manager_app_create"
        }
    },
    {
        path: '/servers/create',
        name: 'servers.create',
        component: CreateServerPage,
        meta: {
            permissions: "manager_app_create"
        }
    },
    {
        path: '/servers/:id/edit',
        name: 'servers.edit',
        component: EditServerPage,
        meta: {
            permissions: "manager_app_create"
        }
    },
]