import Api from '../../Packages/api'

export default {
    namespaced: true,
    state: {
        postbacks: {},
        filters: {},
    },

    getters: {
        getPostbacks: state => state.postbacks,
        getFilters: state => state.filters,
    },

    mutations: {
        setPostbacks(state, data) {
            state.postbacks = data
        },
        setFilters(state, data) {
            state.filters = data
        }
    },

    actions: {
        loadPostbacks({ commit }, payload) {
            Api.octopus.analytic.v1.apps.postbacks(payload)
                .then(data => commit('setPostbacks', data))
        },
        loadFilters({ commit }, id) {
            Api.octopus.analytic.v1.apps.filters(id)
                .then(data => commit('setFilters', data))
        },
    }
}