<template>
    <div class="column label-box">
        <div v-show="isShowLabel" class="label" :class="{ 'none-border-label': isNoBorder }">
            {{ label }}
        </div>
        <div :class="getClasses" class="label-text"
             @mouseleave.prevent.stop="offVisibilityLabel" @mouseover.prevent.stop="onVisibilityLabel">
            <slot>
                {{text}}
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "LdiLabel",
    props: {
        text: String,
        label: String,
        color: {
            type: String,
            default: null,
            validator(color) {
                return ['primary', 'secondary', 'success', 'info', 'warning', 'danger'].includes(color)
            }
        },
        size: {
            type: String,
            default: 'm',
            validator(color) {
                return ['s', 'm', 'l'].includes(color)
            }
        },
        isNoBorder: Boolean
    },
    data: () => ({
        isShowLabel: false,
    }),
    methods: {
        onVisibilityLabel() {
            this.isShowLabel = true
            setTimeout(() => this.isShowLabel = false, 3000)
        },
        offVisibilityLabel() {
            this.isShowLabel = false
        },
    },
    computed: {
        getClasses() {
            const classes = ['btn']
            classes.push('btn--' + this.color)
            classes.push('label-text--size-' + this.size)
            this.isNoBorder ? classes.push('none-border') : ''
            return classes
        },
    }
}
</script>

<style lang="scss" scoped>

.label {
    $zIndex: 20;

    position: absolute;
    top: 0;
    z-index: $zIndex;
    transform: translate3d(0, calc(-100% - .375rem), 0);
    background: var(--color-background-soft);;
    transition: all .3s ease-in-out;
    padding: .25rem .5rem;
    border-radius: .25rem;
    color: var(--color-text);
    width: max-content;
    &-text {
        &--size-s {
            padding: .25rem;
            border-radius: .125rem;
            font-size: .75rem;
            line-height: .75rem;
        }

        &--size-m {
            padding: .25rem .25rem;
            border-radius: .25rem;
            font-size: .875rem;
            line-height: .875rem;
        }

        &--size-l {
            padding: .25rem .375rem;
            border-radius: .5rem;
        }
    }

    &-box {
        position: relative;
        background: var(--color-background);
    }
}
.none-border{
    border: none !important;
    &-label{
        transform: translate3d(-10%, calc(-100% - .375rem), 0);
    }
}
</style>