export const getTitleLinkName = linkName => {
    const findItemByLinkName = itemConfig => itemConfig.hasOwnProperty('link') && linkName === itemConfig.link.name

    let foundItemConfig = getConfigMenu().find(findItemByLinkName)
    if (foundItemConfig) {
        return foundItemConfig.text ?? null
    }

    foundItemConfig = getConfigMenu().find(itemConfig =>
        itemConfig.hasOwnProperty('children') && itemConfig.children.some(findItemByLinkName)
    )

    if (foundItemConfig) {
        return foundItemConfig.children.find(findItemByLinkName).text ?? null
    }

    return null
}
export const getConfigMenu = () => [
    {
        icon: 'apps',
        text: 'Apps',
        link: {name: 'apps'},
    },
    {
        icon: 'servers',
        text: 'Servers',
        link: {name: 'servers'},
    },
    {
        icon: 'fingerprint',
        text: 'Roles',
        link: {name: 'roles'},
    },
    {
        icon: 'user',
        text: 'Users',
        link: {name: 'users'},
    },   
]