<template>
    <UsersLdiPage :breadcrumbs='breadcrumbs'>
        <LdiTabs :initial-active-tab-name='tabs[1]' :tab-names='tabs'
            :initialActiveTabName="this.$route.query.startTabName" >
            <template v-slot='fromSlot'>
                <LdiTab v-bind='fromSlot' :name='tabs[0]'>
                    <EditUserTab v-if="user" :user="user" @onUpdate="showConfirmPopup($event, updateUser, 'you want to change user data?')"/>
                </LdiTab>

                <LdiTab v-bind='fromSlot' :name='tabs[1]' v-if="isBuyerPage">
                    <UserAppTab :source-ids='appIds'
                        @onRemove="showConfirmPopup($event, detachApp, 'you want to deny the user access to this application?')" 
                        :name="user?.name"/>
                </LdiTab>
                
                <LdiTab v-bind='fromSlot' :name='tabs[2]' v-if="isBuyerPage">
                    <UserStreamsTab :paginated="getListStreams" v-if="getListStreams?.data?.length"
                        @onPaginate='paginate'/>
                    <div v-else-if="!getListStreams?.data?.length && user?.name"> User {{ user?.name }} has no available streams</div>
                </LdiTab>
            </template>
        </LdiTabs>

        <LdiPrompt
            :isDisabled="isConfirmDisabled"
            :is-show='isShowConfirmPopup'
            :messages='["Are you sure", popupMessage]'
            @onCancel='closeConfirmPopup' @onConfirm="update" />
    </UsersLdiPage>
</template>

<script>
import { createNamespacedHelpers, mapActions, mapGetters } from 'vuex'
const { mapActions: mapActionsUser, mapGetters: mapGettersUser } = createNamespacedHelpers('users')

import LdiButton from '@/Atoms/LdiButton'
import UsersLdiPage from '@/Pages/UsersLdiPage'
import LdiTabs from '@/Atoms/Tab/LdiTabs'
import LdiTab from '@/Atoms/Tab/LdiTab'
import LdiSpinner from "@/Atoms/LdiSpinner.vue";

import UserAppTab from '@/Components/Users/Tabs/UserAppTab'
import UserStreamsTab from '@/Components/Users/Tabs/UserStreamsTab.vue'
import LdiPrompt from '@/Organisms/Popup/LdiPrompt'
import EditUserTab from '../Tabs/EditUserTab.vue'

export default {
    components: { UserAppTab, LdiTab, LdiTabs, LdiButton, UsersLdiPage, UserStreamsTab, LdiPrompt, LdiSpinner, EditUserTab },
    data: () => ({
        user: null,
        appIds: [],

        isShowConfirmPopup: false,
        popupMessage: null,
        isConfirmDisabled: false,

        configuredObject: null,
        action: null,
    }),
    props: {
        tabs: Array,
        isBuyerPage: {
            type: Boolean,
            default: false
        },
        pageName: String,
        loadUser: Function,
        updateUser: Function,
        getUsers: Object,
    },
    methods: {
        ...mapActions(['loadListApps', 'loadListStreams']),
        ...mapActionsUser(['detachApp', 'loadUsers']),

        update() {
            this.isConfirmDisabled = true
            this.action({ id: this.$route.params.id, ...this.configuredObject})
                .finally(() => {
                    this.closeConfirmPopup()
                    setTimeout(() => {
                       this.loadUser(this.$route.params.id)
                            .then(data => {
                                this.appIds = data.appIds ?? []
                                this.user = data
                            })
                    }, 1000);
                    this.isConfirmDisabled = false
                })
        },
        showConfirmPopup(configuredObject, action, msg) {
            this.isShowConfirmPopup = true
            this.configuredObject = configuredObject
            this.action = action
            this.popupMessage = msg
        },

        closeConfirmPopup() {
            this.isShowConfirmPopup = false
            this.configuredObject = null
        },
        paginate(page) {
            this.loadListStreams({userId: this.$route.params.id, page})
        },
    },

    computed: {
        ...mapGetters(['getListStreams']),

        breadcrumbs() {
            return { text: `Edit ${this.pageName}: ` + this.user?.name }
        },
    },
    created() {
        this.breadcrumbs.to = this.$route.name
    },
    mounted() {
        this.loadUser(this.$route.params.id)
            .then(data => {
                this.appIds = data.appIds ?? []
                this.user = data
            })
            .catch(() => this.$router.go(-1))
        if (this.isBuyerPage) {
            this.loadListApps()
            this.loadListStreams({ userId: this.$route.params.id})
        }
        this.loadUsers()
    },
}
</script>