<template>
    <div class='column'>
        <LdiButton action='save' icon style='align-self: flex-end' @click='emitOnUpdate' :disabled="isDisabled" />
        <div class='block-flex'>
            <label class='column'>
                <span :class="{ 'danger': isError['name'] }">Name</span>
                <input ref='inputName' v-model='name' placeholder='John' type='text'
                    :class="[{ 'error': isError['name'] }]" @input="nameValidation">
                <span v-if="isError['name']" class="danger">{{ errorMessage['name'] }}</span>
            </label>
            <label class='column'>
                <span :class="{ 'danger': isError['email'] }">Email</span>
                <input v-model='email' placeholder='example@email.com' type='email'
                    :class="[{ 'error': isError['email'] }]" @input="emailValidation">
                <span v-if="isError['email']" class="danger">{{ errorMessage['email'] }}</span>
            </label>
            <label class='column'>
                <span :class="{ 'danger': isError['password'] }">Password</span>
                <input v-model='password' autocomplete='off' placeholder='Password' type='text'
                    :class="[{ 'error': isError['password'] }]" @input="passwordValidation">
                <span v-if="isError['password']" class="danger">*Minimum length of 8 characters, must
                    contain at least one digit, at least one letter in lower and uppercase</span>
            </label>
        </div>
        <label class='label-flex'>
            <input v-model='hasAccess' :checked='hasAccess' type='checkbox'>
            Has Access
        </label>
        <div class='column'>
            <span>Roles</span>
            <label v-for='role in getListRoles' :key='role.id' class='label-flex'>
                <input :id='role.label' v-model='checkedRoles' :value='role.label' type='checkbox'>
                {{ role.label }}
            </label>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import LdiButton from '@/Atoms/LdiButton'

export default {
    emits: ['onUpdate'],

    components: { LdiButton },
    data: () => ({
        name: '',
        email: '',
        password: '',
        hasAccess: false,
        checkedRoles: [],

        startingName: null,
        startingEmail: null,
        startingPassword: '',
        startingHasAccess: null,
        startingRoles: [],

        isError: {},
        errorMessage: {},
    }),
    props: {
        user: Object
    },
    watch: {
        name: 'nameValidation',
        email: 'emailValidation',
        password: 'passwordValidation',
    },
    methods: {
        emitOnUpdate() {
            this.$emit('onUpdate', {data: this.createQuery})
        },
        isAlreadyExist(dataName) {
            return this.getUsers?.some((user) => this[dataName]?.trim() === user[dataName] && user.id != this.$route.params.id)
        },
        nameValidation() {
            const namePattern = /^.{3,}$/;
            const existMessage = '*User with this name already exists'
            const invalidMessage = '*Provide a valid name (min length - 3)'
            return this.validation(this.name, namePattern, 'name', existMessage, invalidMessage)
        },
        emailValidation() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const existMessage = '*User with this email already exists'
            const invalidMessage = '*Provide a valid email address'
            return this.validation(this.email, emailPattern, 'email', existMessage, invalidMessage)
        },
        passwordValidation() {
            const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
            return this.validation(this.password, passwordPattern, 'password')
        },
        validation(text, regularExpression, inputType, existMessage, invalidMessage) {
            if (regularExpression.test(text)) {
                this.isError[inputType] = false
                if (this.isAlreadyExist(inputType)) {
                    this.errorMessage[inputType] = existMessage
                    this.isError[inputType] = true
                } else {
                    this.isError[inputType] = false
                }
            } else {
                if (this[inputType]) {
                    this.errorMessage[inputType] = invalidMessage
                    this.isError[inputType] = true
                } else {
                    this.isError[inputType] = false
                }
            }
        },
        areRolesEqual(array1, array2) {
            if (array1.length !== array2.length) {
                return false;
            }
            return array1.every((value, index) => value === array2[index]);
        }
    },

    computed: {
        ...mapGetters(['getListRoles']),

        createQuery() {
            return {
                name: this.name,
                email: this.email,
                hasAccess: this.hasAccess,
                roles: this.checkedRoles,
                password: this.password
            }
        },
        isDisabled() {
            return !this.email
                || !this.name
                || !this.checkedRoles.length
                || Object.entries(this.isError).some(([key, value]) => value)
                || this.isUnchanged
        },
        isUnchanged() {
            return (
                this.name === this.startingName &&
                this.email === this.startingEmail &&
                this.password === this.startingPassword &&
                this.hasAccess === this.startingHasAccess &&
                this.areRolesEqual(this.checkedRoles, this.startingRoles)
            )
        },
    },
    mounted() {
        if (this.user) {
            this.name = this.user?.name ?? ''
            this.email = this.user?.email ?? ''
            this.hasAccess = this.user?.hasAccess ?? false
            this.checkedRoles = this.user?.roles ?? []
            
            this.startingName = this.name;
            this.startingEmail = this.email;
            this.startingHasAccess = this.hasAccess;
            this.startingRoles = [...this.checkedRoles];
        }
    },
}
</script>