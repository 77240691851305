<template>
    <div class="column">
        <LdiButton :disabled='isDisabled' action='save' icon style='align-self: flex-end' @click='emitOnConfigured'/>
        <div :class="[{ 'grid grid--2': isPc }]">
            <div class="grid grid--1">
                <div class="block settingsBlock">
                    <ValidatedField v-model="name" :source="source" field="name" placeholder="app name" @onUpdate="checkErrors">
                        App name
                    </ValidatedField>
                    <ValidatedField v-model="bundle" :source="source" field="bundle" placeholder="like in firebase json" @onUpdate="checkErrors">
                        Bundle
                    </ValidatedField>
                    <ValidatedField :canEditDomain="canEditDomain()" v-model="domain" :source="source" field="domain" placeholder='without "https://"' @onUpdate="checkErrors">
                        Domain
                        <div v-if="!canEditDomain()" class="row row--ai-center">
                            <input v-model="domain" disabled placeholder='without "https://"' type="text" style="padding-right: 2rem">
                            <LdiIcon action="edit" @click="startEditDomain" class="editIcon"/>
                        </div>
                    </ValidatedField>
                    <ExpirationSslIcons :data="source ?? {}" >
                        <v-select v-model="serverName" :options="getListServers"
                            label="name" placeholder='select server'/>
                        <LdiButton @click="createDomainConfig">
                            <LdiEllipsisLoader v-show="isLoading"/>
                            Create config and ssl 
                        </LdiButton>
                    </ExpirationSslIcons>
                    <v-select v-model='vertical' :options='getListVerticals' :reduce='option => option.id'
                        class='v-select small' placeholder='select vertical'>
                    </v-select>
                </div>
            
                <div class="block settingsBlock">
                    <ValidatedField v-model="createApfDevKey" :source="source" placeholder="dev key" :field="'createApfDevKey'" @onUpdate="checkErrors">
                        APF SDK Key
                    </ValidatedField>
                    <ValidatedField v-model="firebaseCMApiKey" :source="source" placeholder="api key for user push message" :field="'firebaseCMApiKey'" @onUpdate="checkErrors">
                        Firebase CM Api Key
                    </ValidatedField>
                    <ValidatedField v-model="GAApiSecret" :source="source" placeholder="api secret that is generated through the Google Analytic UI" :field="'GAApiSecret'" @onUpdate="checkErrors">
                        GA Api secret
                    </ValidatedField>
                    <ValidatedField v-model="firebaseAppID" :source="source" placeholder="the identifier for a Firebase app" :field="'firebaseAppID'" @onUpdate="checkErrors">
                        Firebase App ID
                    </ValidatedField>
                </div>
            </div>
        
            <div class="block settingsBlock">
                <FireBaseConfigurator v-if="!isCreateMode" :fileSource='source' @onConfiguredFile="emitOnConfiguredFile"/>
                <div class="block settingsBlock">
                    <v-select v-model='selectedUserIds' :options='getNotAddedUser' 
                        class='small' multiple placeholder='add user for use this App'>
                    </v-select>
                    <div style=" overflow-x: auto; width: 100%;">
                        <table class='users-table' v-if="buyers?.length">
                            <thead>
                                <tr>
                                    <th scope='col'>Name</th>
                                    <th scope='col'>Email</th>
                                    <th scope='col'></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for='user in buyers' v-bind:key='user.id'>
                                    <td>{{ user.name ?? '-' }}</td>
                                    <td>{{ user.email ?? '-' }}</td>
                                    <td> <LdiIcon action='remove' @click="removeUser(user.id)"/> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LdiButton from '@/Atoms/LdiButton'
import LdiIcon from "@/Atoms/LdiIcon";
import LdiEllipsisLoader from '@/Atoms/LdiEllipsisLoader'
import ExpirationSslIcons from './ExpirationSslIcons.vue'
import ValidatedField from './ValidatedField.vue'
import FireBaseConfigurator from '@/Components/Apps/Component/FireBaseConfigurator.vue'

export default {
    name: "AppConfigurator",
    emits: ['onConfigured', 'onConfiguredFile'],
    components: { LdiButton, LdiEllipsisLoader, ExpirationSslIcons, LdiIcon, ValidatedField, FireBaseConfigurator },
    props: {
        source: {
            type: Object,
            default: null
        },
        isCreateMode: Boolean
    },

    data: () => ({
        name: null,
        bundle: null,
        domain: null,
        vertical: null,
        buyers: null,

        createApfDevKey: null,
        firebaseCMApiKey: null,
        GAApiSecret: null,
        firebaseAppID: null,

        userIds: null,
        selectedUserIds: [],

        serverName: '',
        isLoading: false,

        isEditDomainMode: false,

        isShowErrorMessage: {},

        startValue: {},
    }),

    watch: {
        source(newSource) {
            this.userIds = newSource?.buyers.map(buyer => buyer.id)
            this.getAddedUser(newSource?.buyers);
        },
    },
    
    computed: {
        ...mapGetters(['getListVerticals', 'getListUsers', 'getListServers', 'isPc']),
        isDisabled() {
            return !this.name
                || !this.bundle
                || !this.domain
                || !this.vertical
                || Object.entries(this.isShowErrorMessage).some(([key, value]) => value)
                || this.isUnchanged
        },
        isUnchanged() {
            return (
                this.name === this.startValue.name &&
                this.bundle === this.startValue.bundle &&
                this.domain === this.startValue.domain &&
                this.vertical === this.startValue.vertical &&
                this.createApfDevKey === this.startValue.createApfDevKey &&
                this.firebaseCMApiKey === this.startValue.firebaseCMApiKey &&
                this.GAApiSecret === this.startValue.GAApiSecret &&
                this.firebaseAppID === this.startValue.firebaseAppID &&
                this.startValue.buyers.every(obj => this.userIds.includes(obj.id)) &&
                !this.selectedUserIds.length &&
                this.serverName === this.startValue.serverName 
            )
        },
        getConfiguredObject(){
            return {
                name: this.name,
                bundle: this.bundle,
                domain: this.domain,
                vertical: this.vertical,
                keys: {
                    createApfDevKey: this.createApfDevKey
                },
                privateKeys: {
                    firebaseCMApiKey: this.firebaseCMApiKey,
                    GAApiSecret: this.GAApiSecret,
                    firebaseAppID: this.firebaseAppID,
                },
                userIds: this.userIds?.length ? [...this.userIds, ...this.selectedUserIds.map(user => user.id)] : [...this.selectedUserIds.map(user => user.id)],
            }
        },
        
        getNotAddedUser(){
            return this.getListUsers?.filter((user) => !this.userIds?.includes(user?.id));
        },
    },
    methods: {
        ...mapActions(['loadListBuyers', 'apps/createDomainConfig', 'loadServers', 'apps/loadApps']),
        getAddedUser(source) {
            this.buyers = source.filter((user) => this.userIds?.includes(user?.id));
        },
        emitOnConfigured(){
            this.$emit('onConfigured', this.getConfiguredObject)

            this.selectedUserIds = []
            this.addStartValue()
        },
        emitOnConfiguredFile(obj) {
            this.$emit("onConfiguredFile", obj);
        },
        checkErrors(isShowErrorMessage){
            this.isShowErrorMessage = {...this.isShowErrorMessage, ...isShowErrorMessage}
        },
        createDomainConfig(){
            this.isLoading = true
            
            this['apps/createDomainConfig']({
                domain: this.domain,
                serverName: this.serverName
            }).then(() => this.isLoading = false)
        },
        startEditDomain() {
            this.isEditDomainMode = true;
        },
        canEditDomain() {
            return this.source === null || this.isEditDomainMode;
        },
        removeUser(id) {
            this.addStartValue()

            this.userIds = this.userIds.filter(userId => userId !== id)
            this.getAddedUser(this.source?.buyers)
        },
        addStartValue(){
            this.startValue = {
                name: this.name,
                bundle: this.bundle,
                domain: this.domain,
                vertical: this.vertical,
                createApfDevKey: this.createApfDevKey,
                firebaseCMApiKey: this.firebaseCMApiKey,
                GAApiSecret: this.GAApiSecret,
                firebaseAppID: this.firebaseAppID,
                selectedUserIds: this.selectedUserIds,
                serverName: this.serverName,
                buyers: this.buyers
            }
        }
    },
    mounted() {
        if (this.source) {
            this.name = this.source.name
            this.bundle = this.source.bundle
            this.domain = this.source.domain
            this.vertical = this.source.vertical
            this.createApfDevKey = this.source.keys.createApfDevKey
            this.firebaseCMApiKey = this.source.private_keys.firebaseCMApiKey
            this.GAApiSecret = this.source.private_keys.GAApiSecret
            this.firebaseAppID = this.source.private_keys.firebaseAppID
            this.userIds = this.source?.buyers.map(buyer => buyer.id)
            this.getAddedUser(this.source?.buyers)

            this.addStartValue()
        }
        this.loadServers()
        this.loadListBuyers()
    }
}
</script>

<style lang="scss">
.editIcon{
    position: absolute;
    right: var(--form-inputs-padding);
}

.settingsBlock{
    border: 1px solid var(--border-color);
}
.users-table{
    background-color: var(--color-background);
}
</style>