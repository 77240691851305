<template>
    <div class='scroll-thin' style='overflow-x: auto'>
        <LdiPaginatedTable
            :initialOrderBy='getRowHeaderCellsConfiguration()[3].cellNameOrderAble'
            :paginated='paginated'
            :rowHeaderCellsConfiguration='getRowHeaderCellsConfiguration()'
            @onOrder='emitOnOrder' @onPaginate='emitOnPaginate'>
            <tr v-for='item in paginated?.data' :key="item.id" 
                :class="[getRowClass(item), { 'bg-checked': item.ip === postbackIp }]">
                <td>{{ item.id }}</td>
                <td>
                    <p class="row row--ai-center row--nowrap">
                        <LdiIcon :action="item.country ?? 'ALL'"/>
                        {{item.country ?? 'N/A'}} {{ item.ip }}
                    </p>
                </td>
                <td>{{ item.stream?.name ?? '-' }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.type }}</td>
                <td>
                    <div class='row row--nowrap'>
                        <LdiButton v-if='item.body_request' action='show' :color="item.error ? 'danger' : 'success'" 
                        icon size='s' @click="emitOnShow({ 'body_request': item.body_request, 'body_response': item.body_response, 'error': item.error, 'additional_data': item.additional_data }, item, 'request')" />
                    </div>
                </td>
            </tr>
        </LdiPaginatedTable>
    </div>
</template>

<script>
import LdiPaginatedTable from '@/Organisms/Table/LdiPaginatedTable'
import LdiButton from '@/Atoms/LdiButton'
import LdiIcon from '@/Atoms/LdiIcon.vue'

export default {
    name: "AppStatisticsTable",
    emits: ['onPaginate', 'onOrder', 'onShow', 'onCopy'],
    components: { LdiButton, LdiPaginatedTable, LdiIcon },
    props: {
        paginated: {
            type: Object
        },
        postbackIp: String
    },
    data: () => ({
        clickedItem: null
    }),
    methods: {
        getRowHeaderCellsConfiguration() {
            return [
                {
                    cellName: '#',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyId'
                },
                {
                    cellName: 'IP',
                    isOrderAble: false
                },
                {
                    cellName: 'Stream',
                    isOrderAble: false,
                },
                {
                    cellName: 'Date',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyDate'
                },
                {
                    cellName: 'Req_Type',
                    isOrderAble: true,
                    cellNameOrderAble: 'keyType'
                },
                {
                    cellName: 'JSON Body',
                    isOrderAble: false
                }
            ]
        },
        emitOnOrder(orderConfiguration) {
            this.$emit('onOrder', orderConfiguration)
        },
        emitOnPaginate(page) {
            this.$emit('onPaginate', page)
        },
        emitOnShow(json, item, jsonFrom) {
            this.clickedItem = item
            this.$emit('onShow', json, jsonFrom)
        },
        getRowClass(item) {
            return {
                'bg-soft': (this.clickedItem === item)
            }
        },
        resetClickedItem() {
            this.clickedItem = null
        }
    }
}
</script>