import {IS_NEED_PRINT_LOGS} from "../api/config";

export const log = response => {
    if (IS_NEED_PRINT_LOGS) {
        console.log("response:", response)
    }
    return response
}

export const endLoading = (error, store) => {
    store.commit('setIsLoading', false)
}