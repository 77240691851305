<template>
    <edit-user-configurator :tabs="$options.TABS" pageName="user"
        :loadUser="loadUser" :updateUser="updateUser" :getUsers="getUsers"/>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapActions: mapActionsUser,  mapGetters: mapGettersUsers } = createNamespacedHelpers('users')

import EditUserConfigurator from './Components/EditUserConfigurator.vue'

export default {
    TABS: ['Settings'],
    
    components: { EditUserConfigurator },

    mounted() {
        this.loadUsersListRoles() 
    },
    computed: {
        ...mapGettersUsers(['getUsers'])
    },

    methods: {
        ...mapActions(['loadUsersListRoles']),
        ...mapActionsUser(['loadUser', 'updateUser']),
    },
}
</script>