import Api from '@/Packages/api'
import { str } from '@/Packages/utils'
import { verticals } from '@/Packages/config'

export default {
    state: {
        listPermissions: [],
        listRoles: [],
        listApps: [],
        listVerticals: [
            { id: verticals.BET, label: str.ucFirst(verticals.BET).replaceAll("_", " ") },
            { id: verticals.CASINO, label: str.ucFirst(verticals.CASINO).replaceAll("_", " ") },
            { id: verticals.SOCIAL_CASINO, label: str.ucFirst(verticals.SOCIAL_CASINO).replaceAll("_", " ") },
            { id: verticals.FANTASY, label: str.ucFirst(verticals.FANTASY).replaceAll("_", " ") },
            { id: verticals.FINANCE, label: str.ucFirst(verticals.FINANCE).replaceAll("_", " ") }
        ],
        listUsers: [],
        listStreams: [],
        listServers: [],
    },

    getters: {
        getListPermissions: state => state.listPermissions,
        getListRoles: state => state.listRoles,
        getListApps: state => state.listApps,
        getListVerticals: state => state.listVerticals,
        getListUsers: state => state.listUsers,
        getListStreams: state => state.listStreams,
        getListServers: state => state.listServers,
    },

    mutations: {
        setListPermissions(state, data) {
            state.listPermissions = data
        },
        setListRoles(state, data) {
            state.listRoles = data
        },
        setListApps(state, data) {
            state.listApps = data
        },
        setListUsers(state, data){
            state.listUsers = data
        },
        setListStreams(state, data){
            state.listStreams = data
        },  
        setListServers(state, data){
            state.listServers = data.servers
        },  
    },

    actions: {
        loadUsersListPermissions({ commit }) {
            Api.octopus.analytic.v1.lists.usersPermissions().then(data => commit('setListPermissions', data))
        },
        loadBuyersListPermissions({ commit }) {
            Api.octopus.analytic.v1.lists.buyersPermissions().then(data => commit('setListPermissions', data))
        },
        loadUsersListRoles({ commit }) {
            Api.octopus.analytic.v1.lists.usersRoles().then(data => commit('setListRoles', data))
        },
        loadBuyersListRoles({ commit }) {
            Api.octopus.analytic.v1.lists.buyersRoles().then(data => commit('setListRoles', data))
        },
        loadListApps({ commit }) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.lists.apps()
                    .then(data => {
                        commit('setListApps', data)
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        loadListUsers({commit}){
            Api.octopus.analytic.v1.lists.users().then(data => commit('setListUsers', data))
        },
        loadListBuyers({commit}){
            Api.octopus.analytic.v1.lists.buyers().then(data => commit('setListUsers', data))
        },
        loadListStreams({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Api.octopus.analytic.v1.lists.streams(payload)
                    .then(data => {
                        commit('setListStreams', data.streams)
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        loadServers({ commit }) {
            Api.octopus.analytic.v1.lists.servers().then(data => commit('setListServers', data))
        },
    }
}