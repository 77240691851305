<template>

    <table>
        <LdiRowHeaderTable
            :cells='rowHeaderCellsConfiguration' :initial-order-by='initialOrderBy'
            @onOrderByCell='emitOnOrder' />

        <tbody>
        <slot></slot>
        </tbody>

    </table>

    <LdiPagesPaginator
        :current-page='paginated.current_page' :last-page='paginated.last_page' :links='paginated.links'
        @onPaginate='emitOnPaginate'
    />

</template>

<script>
import LdiPagesPaginator from '@/Organisms/LdiPagesPaginator'
import LdiRowHeaderTable from '@/Organisms/Row/LdiRowHeaderTable'

export default {
    emits: ['onPaginate', 'onOrder'],
    components: { LdiRowHeaderTable, LdiPagesPaginator },
    props: {
        rowHeaderCellsConfiguration: {
            type: Array
        },
        initialOrderBy: String,
        paginated: {
            type: Object
        },
    },
    methods: {
        emitOnOrder(orderConfiguration) {
            this.$emit('onOrder', orderConfiguration)
        },
        emitOnPaginate(page) {
            this.$emit('onPaginate', page)
        }
    }
}
</script>

<style scoped>

</style>