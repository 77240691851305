import Api from '../../Packages/api'

export default {
    state: {
        permissions: [],
    },

    getters: {
        getPermissions: state => state.permissions,
    },

    mutations: {
        setPermissions(state, data) {
            state.permissions = data
        },
    },

    actions: {
        loadPermissions({commit}) {
            Api.octopus.analytic.v1.roles.getPermission()
                .then(data => commit('setPermissions', data))
        },
    },
}