<template>
    <div class="grid filters-block">
        <div class="grid filters-grid">
            <div class="row row--ai-center filters-container">
                <input type="text" v-model="search" placeholder="search by client uid or ip" class="middle">
                <v-select :options="filters?.type" v-model="type" placeholder="select type" class="middle" multiple/>
                <v-select :options="filters?.streamUid" v-model="streamUid" placeholder="select stream uid" 
                    label="name" :reduce='option => option.uid' class="middle"/>
            </div>
            <div class="row row--ai-center">
                <DatePickerComponent :multiple="false" period="start day" v-model:value="start_date"/>
                <DatePickerComponent :multiple="false" period="end day" v-model:value="end_date"/>
                <LdiToggleButton text textOn="Empty App" v-model="emptyPostbacksDomain"/>
                <LdiButton @click="emitOnFilter" :action="'filter'" color="primary" icon size='s' />
            </div>
        </div>
        <LdiLabel label="Before current date" isNoBorder>
            <LdiButton @click="emitOnClearFilter" color="warning" size='s'>
                Clear previous data
            </LdiButton>
        </LdiLabel>
    </div>
</template>

<script>
import { date as Storage } from '@/Packages/storage'
import LdiButton from '@/Atoms/LdiButton'
import LdiLabel from '@/Atoms/Label/LdiLabel.vue'
import DatePickerComponent from '@/Molecules/DatePicker/DatePicker.vue';
import LdiToggleButton from '@/Atoms/LdiToggleButton'

export default {
    name: "PostbacksPageFilter",
    emits: ['onFilter', 'onClearFilter'],
    TYPES: ["create", "receive", "token", "track", "error"],
    components: { LdiButton, DatePickerComponent, LdiLabel, LdiToggleButton },
    props: {
        filters: Object
    },
    data() {
        return {
            search: '',
            type: [],
            streamUid: [],
            start_date: '',
            end_date: '',
            emptyPostbacksDomain: false
        };
    },
    methods:{
        emitOnFilter() {
            Storage?.removeDate()
            this.$emit('onFilter', this.getFilterData());
        },
        emitOnClearFilter(){
            this.type = []
            this.streamUid = []
            this.search = ''
            this.start_date = ''
            this.end_date = ''
            this.getFormattedDate()
            this.$emit('onClearFilter', {...this.getFilterData(), startDate: Storage?.getDate()});
        },
        getFilterData(){
            const filterData = {
                search: this.search,
                type: this.type,
                streamUid: this.streamUid,
                startDate: this.start_date,
                endDate: this.end_date,
                emptyPostbacksDomain: this.emptyPostbacksDomain
            };
            return filterData
        },
        getFormattedDate(){
            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            const options = {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false, 
              timeZone: userTimezone
            };

            const currentDateTime = new Date()
                .toLocaleString('en-US', options)
                .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2') 
                .replace(',', ''); 
            
            Storage.setDate(currentDateTime)

            return currentDateTime
        },
    },
    mounted(){
        if (Storage?.getDate()) {
            this.$emit('onClearFilter', {...this.getFilterData(), startDate: Storage?.getDate()});
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/base/mixins.scss';
.filters{
    &-block{
        gap: 0.5rem;
        margin-bottom: 1rem;
    }
    &-grid{
        gap: 0.5rem;
        @include bp(xxxl){
            grid-template-columns: 1fr 1fr;
        }
    }
    &-container{
        @include bp(md){
            flex-wrap: nowrap;
        }
    }
}
.v-select {
    width: 100%;
    margin: 0;
}
</style>