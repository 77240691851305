<template>
    <ServersLdiPage>
        <LdiButton icon @click='create' />

        <ServersTable
            :initialOrderBy='orderConfiguration.orderBy' :paginated='this.getServers'
            @onEdit='edit' @onOrder='order' @onPaginate='paginate' @onRemove='showRemoveConfirm'
        />

        <LdiPrompt
            :is-show='isShowDeleteConfirm'
            :messages='["Are you sure?", "All data will be DELETED!"]'
            @onCancel='closeRemoveConfirm' @onConfirm='remove'
        />

    </ServersLdiPage>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ServersTable from '@/Components/Servers/Table/ServersTable'
import LdiPrompt from '@/Organisms/Popup/LdiPrompt'
import ServersLdiPage from '@/Pages/ServersLdiPage'
import LdiButton from '@/Atoms/LdiButton'

const { mapActions, mapGetters } = createNamespacedHelpers('servers')

export default {
    components: { LdiButton, ServersLdiPage, LdiPrompt, ServersTable },
    data: () => ({
        filters: null,
        page: null,
        orderConfiguration: {
            orderBy: 'keyC',
            orderDirection: 'desc'
        },

        isShowDeleteConfirm: false,
        idToRemove: null
    }),
    mounted() {
        this.loadServers()
    },
    computed: {
        ...mapGetters(['getServers'])
    },
    methods: {
        ...mapActions(['loadServers', 'removeServer']),

        order(orderConfiguration) {
            this.orderConfiguration = orderConfiguration
            this.loadServers({ ...orderConfiguration, page: this.page })
        },

        paginate(page) {
            this.page = page
            this.loadServers({ ...this.orderConfiguration, page })
        },

        create() {
            this.$router.push({ name: 'servers.create' })
        },
        edit(id) {
            this.$router.push({ name: 'servers.edit', params: { id } })
        },
        remove() {
            this.removeServer(this.idToRemove).finally(this.closeRemoveConfirm)
        },

        showRemoveConfirm(id) {
            this.idToRemove = id
            this.isShowDeleteConfirm = true
        },

        closeRemoveConfirm() {
            this.idToRemove = null
            this.isShowDeleteConfirm = false
        }

    }
}
</script>