<template>
    <create-role-configurator :loadListPermissions="loadBuyersListPermissions" pageName="buyer"/>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CreateRoleConfigurator from "./Components/CreateRoleConfigurator.vue";

export default {
    components: { CreateRoleConfigurator },

    methods: {
        ...mapActions(['loadBuyersListPermissions']),
    }
}
</script>