import GlobalComponents from './plugin/GlobalComponents/index.js';
import Notification from './plugin/Notification/index.js';

import { createApp } from 'vue'
import {router} from './router.js';
import store from "./Store";

import Api from './Packages/api'
import Middlewares from './Packages/middlewares'

import App from './App.vue'

import './assets/scss/index.scss';
import {isMobile} from "@/Packages/utils/types/media";

window.log = function () {
    if (process.env.VUE_APP_IS_NEED_PRINT_LOGS === 'true') {
        console.log(...arguments)
    }
}

function handleWindowResize() {
    if (isMobile()) {
        if (!store.getters.isMob) {
            store.commit('setMob')
        }
    } else {
        if (!store.getters.isPc) {
            store.commit('setPc')
        }
    }
}

window.addEventListener('resize', handleWindowResize);
window.addEventListener('load', handleWindowResize);

Api.config
    .useStore(store)
    .useRouter(router)
    .useMiddlewares(Middlewares)

const vueApp = createApp(App)
    .use(router)
    .use(store)

    .use(Notification)
    .use(GlobalComponents)
    
//настройка нужна для работы provide/inject с реактивностью
vueApp.config.unwrapInjectedRef = true

vueApp.mount('#app')