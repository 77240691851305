import { routeMenu } from "./routeMenu";
import LoginComponent from "../../Components/Auth/LoginComponent.vue";
import GoogleOauthComponent from "../../Components/Auth/GoogleOauthComponent.vue";
import HomeComponent from "../../Components/HomeComponent.vue";
import Utils from "../utils";

export const routes = [
    {
        path: "/login",
        name: "login",
        component: LoginComponent
    },
    {
        path: "/oauth/google",
        name: "oauth.google",
        component: GoogleOauthComponent
    },
    {
        path: "/",
        component: HomeComponent,
        meta: {
            requiresAuth: true,
            hasAccess: true
        },
        children: routeMenu
    }
];

export const getRoutesAvailableForUser = () => {
    return routeMenu.filter(menuItem => {
        const permission = menuItem.meta?.permissions
        if (Array.isArray(permission)) {
            return permission.length ? Utils.auth.isUserCan(permission) : true
        } else if (typeof permission === "string") {
            return Utils.auth.isUserCan(permission)
        }
        return false
    })
}

export const getRoutesNamesAvailableForUser = () => getRoutesAvailableForUser()
    .map(menuItem => menuItem.name ?? null)
    .filter(menuName => menuName !== null)