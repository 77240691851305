<template>
    <li v-if="!perm || userCan(perm)">
        <template v-if="children.length === 0">
            <router-link :to="link" @click="this.$emit('close:menu')" 
                :class="['sidebar-link', {'router-link-exact-active': $route.path.startsWith(`/${link.name}`)}]">
                <LdiIcon :action="icon" class="icon-sidebar" size="m"/>
                <span class="sidebar-text">{{ text }}</span>
            </router-link>
        </template>
        <template v-else>
            <div class="groups-box" @click="showGroupsLinks">
                <div class="sidebar-link">
                    <LdiIcon :action="icon" class="icon-sidebar" size="m"/>
                    <span class="sidebar-text"> {{ text }} </span>
                    <Icon :class="['groups-arrow', { 'groups-rotate': isListOpen }]" icon="bxs:down-arrow"/>
                </div>
                <ul :class="['groups-list', { 'groups-list-open': isListOpen }]">
                    <SidebarItemComponent v-for="menu in children" :children="menu.children ?? []" 
                                          :key="menu.text"
                                          :icon="menu.icon"
                                          :link="menu.link ?? {}"
                                          :perm="typeof menu.perm === 'string' ? menu.perm : menu.perm.forEach(p => p)"
                                          :text="menu.text"
                                          @click="handleCloseList" @close:menu="this.$emit('close:menu')"/>
                </ul>
            </div>
        </template>
    </li>
</template>

<script>
import {auth, media as Media} from '@/Packages/utils'
import LdiIcon from "@/Atoms/LdiIcon.vue";
import {Icon} from "@iconify/vue";

export default {
    components: {LdiIcon,Icon},
    props: {
        perm: String,
        link: Object,
        text: String,
        icon: String,
        children: Array,
    },
    data() {
        return {
            isListOpen: false
        }
    },

    methods: {
        userCan: auth.isUserCan,

        showGroupsLinks() {
            this.isListOpen = !this.isListOpen
        },
        handleCloseList() {
            this.isListOpen = Media.isMobile();
        }
    },
}
</script>